import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ReactNotifications } from 'react-notifications-component';
import store from './store';
import storage from './storage';
import App from './views';
import './assets/css/app.css?v=1.10.0';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './themes';
import { LoadingContainer } from 'components';
import 'emoji-mart/css/emoji-mart.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-notifications-component/dist/theme.css';
// preferred way to import (from `v4`). Uses `animate__` prefix.
import 'animate.css/animate.min.css';

// Alternate way to use classes without prefix like `animated fadeIn`
import 'animate.css/animate.compat.css';

const loading = async () => {
  // await ReactDOM.render(<LoadingContainer loading />, document.getElementById('root'));
  // return storage.loadData();
  return true;
};
loading()
  .then(() => {
    ReactDOM.render(
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ReactNotifications />
          <App />
        </ThemeProvider>
      </Provider>,
      document.getElementById('root'),
    );
  })
  .catch((err) => {
    console.error(err);
  });
