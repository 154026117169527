import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CheckboxManager } from './checkbox-manager';

export default function ServicesModal({ open, setOpen, currentServiceList, onSubmitData }) {
  const [services, setServices] = React.useState([]);
  const [disabled, setDisable] = React.useState(true);

  const serviceOptions = open?.arrService?.filter((item) => currentServiceList?.includes(item?.id));

  const handleClose = () => {
    setOpen({});
  };
  React.useEffect(() => {
    setServices([]);
  }, [open]);
  const handleSubmit = React.useCallback(async () => {
    if (onSubmitData) {
      onSubmitData(open, services);
    }
    handleClose();
  }, [services.length, open]);
  const isOpen = Boolean(open.id);
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Vui lòng chọn dịch vụ muốn tạm ngưng</DialogTitle>
        <DialogContent>
          <CheckboxManager
            label="DS Dịch vụ"
            options={serviceOptions}
            value={services}
            onChange={(res) => {
              setDisable(res.length === 0);
              setServices(res);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Hủy</Button>
          <Button onClick={handleSubmit} autoFocus disabled={disabled}>
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
