import React from 'react';
import Utils from 'commons/utils';
import { SearchInputColumn } from 'components';
import { Button, Avatar } from '@mui/material';
import { Circle as CircleIcon } from '@mui/icons-material';

function typeClient(type) {
  if (!type) return '';
  if (type.length < 1) return '';
  return type.map((item, index) => {
    if (item?.totalReturn?.totalResult) {
      return (
        <div key={index}>
          {item?.totalReturn?.serviceName}:{' '}
          <b style={{ color: 'blue' }}>{item?.totalReturn?.totalResult}</b>
        </div>
      );
    }
  });
}
function displayArrService(dataRow, arrClientCareHistory, currentServiceList) {
  if (!dataRow || !currentServiceList) return '';
  const serviceOptions = dataRow?.filter((item) => currentServiceList?.includes(item?.id));

  return serviceOptions?.map((item, index) => (
    <div key={index} style={{ textAlign: 'left' }}>
      <CircleIcon color="primary" sx={{ fontSize: 10, marginRight: '5px' }} />
      {item.name}
      <b>{arrClientCareHistory.find((el) => el.serviceId == item.id) && ' (Deal)'}</b>
    </div>
  ));
}

export const Columns = (handleSearchColumn, controlHoldingClient, currentServiceList) => [
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: <SearchInputColumn title="Mã" name="code" onChange={handleSearchColumn} />,
    width: '10px',
    align: 'center',
  },
  {
    id: 'urlImage',
    numeric: true,
    disablePadding: false,
    label: 'Logo',
    align: 'center',
    alignTitle: 'center',
    render: (row) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Avatar
          alt={row?.code}
          sx={{
            width: 60,
            height: 60,
          }}
          src={`${process.env.REACT_APP_API_URL}/${row?.urlImage}`}
        />
      </div>
    ),
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: <SearchInputColumn title="Khách hàng" name="name" onChange={handleSearchColumn} />,
    align: 'left',
    width: '200px',
  },
  {
    id: 'arrService',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn title="DS Dịch vụ" name="arrService.name" onChange={handleSearchColumn} />
    ),
    align: 'left',
    width: '200px',
    render: (row) =>
      displayArrService(row.arrService, row.arrClientCareHistory, currentServiceList),
  },
  {
    id: 'tax_Code',
    numeric: true,
    disablePadding: false,
    label: <SearchInputColumn title="Mã số thuế" name="tax_Code" onChange={handleSearchColumn} />,
    align: 'center',
    width: '100px',
  },
  {
    id: 'arrAddress',
    numeric: true,
    disablePadding: false,
    label: <SearchInputColumn title="Địa chỉ" name="arrAddress" onChange={handleSearchColumn} />,
    align: 'left',
    width: '300px',
    render: (row) => Utils.showArrayInTale(row.arrAddress),
  },
  {
    id: 'arrNumberPhone',
    numeric: true,
    disablePadding: false,
    label: <SearchInputColumn title="SĐT" name="arrNumberPhone" onChange={handleSearchColumn} />,
    align: 'center',
    width: '100px',
    render: (row) => Utils.showArrayInTale(row.arrNumberPhone),
  },
  {
    id: 'arrFax',
    numeric: true,
    disablePadding: false,
    label: <SearchInputColumn title="Fax" name="arrFax" onChange={handleSearchColumn} />,
    align: 'left',
    width: '100px',
    render: (row) => Utils.showArrayInTale(row.arrFax),
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: <SearchInputColumn title="Email" name="email" onChange={handleSearchColumn} />,
    align: 'left',
    width: '100px',
  },
  {
    id: 'foundingDate',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn title="Thành lập" name="foundingDate" onChange={handleSearchColumn} />
    ),
    align: 'center',
    width: '100px',
    render: (row) => Utils.formatDateFn(row.foundingDate),
  },
  {
    id: 'companyType',
    numeric: true,
    disablePadding: false,
    label: <SearchInputColumn title="Loại CT" name="companyType" onChange={handleSearchColumn} />,
    align: 'center',
    width: '150px',
  },
  {
    id: 'arrClientEvaluationCriteria',
    numeric: true,
    disablePadding: false,
    label: 'Loại KH',
    align: 'center',
    width: '200px',
    render: (row) => typeClient(row?.arrClientEvaluationCriteria),
  },
  {
    id: 'cargotype',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn title="Loại hàng hóa" name="cargotype" onChange={handleSearchColumn} />
    ),
    align: 'center',
    width: '100px',
  },
  {
    id: 'representer',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn title="Người đại diện" name="representer" onChange={handleSearchColumn} />
    ),
    align: 'left',
    width: '100px',
  },
  {
    id: 'birthdayRepresenter',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Ngày sinh NĐD"
        name="birthdayRepresenter"
        onChange={handleSearchColumn}
      />
    ),
    align: 'center',
    width: '100px',
    render: (row) => Utils.formatDateFn(row.birthdayRepresenter),
  },
  {
    id: 'emailRepresenter',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn title="Email NĐD" name="emailRepresenter" onChange={handleSearchColumn} />
    ),
    align: 'left',
    width: '100px',
  },
  {
    id: 'phoneRepresenter',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn title="SĐT NĐD" name="phoneRepresenter" onChange={handleSearchColumn} />
    ),
    align: 'center',
    width: '100px',
  },
  {
    id: 'note',
    numeric: true,
    disablePadding: false,
    label: <SearchInputColumn title="Ghi chú" name="note" onChange={handleSearchColumn} />,
    align: 'left',
    width: '100px',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Thao tác',
    width: '200px',
    align: 'center',
    fixed: 'right',
    render: (row) => (
      <Button
        variant="contained"
        size="small"
        color="info"
        sx={{ borderRadius: '15px', textTransform: 'none' }}
        onClick={() => controlHoldingClient(row)}
      >
        Tạm ngưng
      </Button>
    ),
  },
];
