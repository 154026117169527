import * as React from 'react';
import { Box, Divider, Typography, Grid } from '@mui/material';
import { ShowListFile } from 'components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Utils from 'commons/utils';
import ApiServices from 'services';

const TabSchedulementManagement = ({ clientCareHistoryItem }) => {
  const [schedules, setSchedules] = React.useState([]);

  const getScheduleByClientCareHistory = async () => {
    try {
      const { data } = await ApiServices.getScheduleByClientCareHistory(clientCareHistoryItem.id);
      setSchedules(data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getScheduleByClientCareHistory();
  }, []);
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100px',
        backgroundColor: 'rgb(234, 238, 243)',
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        borderRadius: '10px',
        fontSize: '12px',
      }}
    >
      <Typography align="center" component="div" variant="h6" sx={{ marginTop: '0px' }}>
        Lịch sử lịch hẹn
      </Typography>
      <Divider />
      {/* header */}
      <Grid container>
        <Grid item xs={2} sx={{ padding: 1, textAlign: 'center' }}>
          <Typography m={1} align="center" component="b" variant="string">
            Cuộc hẹn
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}>
          <Typography align="center" component="b" variant="string">
            T.gian hẹn
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}>
          <Typography align="center" component="b" variant="string">
            Địa điểm
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}>
          <Typography align="center" component="b" variant="string">
            Nội dung
          </Typography>
        </Grid>
        <Grid item xs={1} sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}>
          <Typography align="center" component="b" variant="string">
            Liên hệ Đối tác
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}>
          <Typography align="center" component="b" variant="string">
            Files
          </Typography>
        </Grid>
      </Grid>
      {/* body - mapdata */}
      {schedules.map((item) => (
        <div key={item.id}>
          <Divider variant="middle" />
          <Grid container>
            <Grid item xs={2} sx={{ padding: 1, textAlign: 'center' }}>
              {item?.appointmentName}
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
            >
              {Utils.formatDateHourFn(item?.time)}
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
            >
              {item?.location}
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
            >
              {item?.note}
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
            >
              {item?.meetingClientName}
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
            >
              <ShowListFile fileList={item?.arrUrlfile} />
            </Grid>
          </Grid>
        </div>
      ))}
    </Box>
  );
};

export { TabSchedulementManagement };
