import React, { useState, useEffect } from 'react';
import ApiServices from 'services';
import { ServiceItem } from './service-item';

const ContentContract = ({ quoteIdList, selected }) => {
  const [quoteList, setQuoteList] = useState([]);

  const getQuoteList = async (idList) => {
    try {
      const { data } = await ApiServices.getDetailQuotes(idList);
      if (data) {
        setQuoteList(data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (quoteIdList.length > 0) {
      getQuoteList(quoteIdList);
    }
  }, []);

  const renderContent = (item, index) => {
    return <ServiceItem key={index} item={item} />;
  };

  return quoteList?.map(renderContent);
};

export { ContentContract };
