import React from 'react';
import Utils from 'commons/utils';
import { Button } from '@mui/material';
import { SearchInputColumn } from 'components';
import { CONTRACT_STATUS } from 'commons/constants';
import { RenderContractStatus } from 'components/custom-status';

export const getColumns = (onSearchInputChange, deleteItem) => [
  {
    id: 'contractNumber',
    numeric: true,
    disablePadding: false,
    align: 'center',
    label: (
      <SearchInputColumn
        title="Số hợp đồng"
        onChange={({ target }) => onSearchInputChange(target.value, 'contractNumber', true)}
      />
    ),
  },
  {
    id: 'contractStatus',
    numeric: false,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Trạng thái"
        onChange={({ target }) => onSearchInputChange(target.value, 'contractStatus', true)}
      />
    ),
    width: '10px',
    align: 'center',
    render: (row) => <RenderContractStatus status={row?.contractStatus} />,
  },
  {
    id: 'referenceNumber',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Số tham chiếu"
        onChange={({ target }) => onSearchInputChange(target.value, 'referenceNumber', true)}
      />
    ),
    align: 'center',
  },
  {
    id: 'typeContractName',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Loại HĐ"
        onChange={({ target }) => onSearchInputChange(target.value, 'typeContractName', true)}
      />
    ),
    align: 'center',
  },
  {
    id: 'clientCompanyName',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Khách hàng"
        onChange={({ target }) => onSearchInputChange(target.value, 'clientCompanyName', true)}
      />
    ),
    width: '300px',
    align: 'center',
  },
  {
    id: 'createdOn',
    numeric: true,
    disablePadding: false,
    label: 'Ngày tạo',
    align: 'center',
    render: (row) => Utils.formatDateFn(row?.createdOn),
  },
  {
    id: 'updatedOn',
    numeric: true,
    disablePadding: false,
    label: 'Ngày cập nhật',
    align: 'center',
    render: (row) => Utils.formatDateFn(row?.updatedOn),
  },
  {
    id: 'effectiveDate',
    numeric: true,
    disablePadding: false,
    label: 'Ngày hiệu lực',
    align: 'center',
    render: (row) => Utils.formatDateFn(row?.effectiveDate),
  },
  {
    id: 'expireDate',
    numeric: true,
    disablePadding: false,
    label: 'Ngày kết thúc',
    align: 'center',
    render: (row) => Utils.formatDateFn(row?.expireDate),
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Thao tác',
    width: '10px',
    fixed: 'right',
    align: 'center',
    render: (row) =>
      (row?.contractStatus === CONTRACT_STATUS.ORIGIN ||
        row?.contractStatus === CONTRACT_STATUS.CANCEL) && (
        <Button
          variant="contained"
          size="small"
          color="error"
          sx={{ borderRadius: '15px', textTransform: 'none' }}
          onClick={() => window.showConfirm(() => deleteItem(row))}
        >
          Xoá
        </Button>
      ),
  },
];
