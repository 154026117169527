import React from 'react';
import { Button, Chip } from '@mui/material';
import Utils from 'commons/utils';
import { QUOTE_STATUS_OPTIONS, QUOTE_STATE } from 'commons/constants';

export const Columns = (deleteContTypeItem) => [
  {
    id: 'code',
    numeric: true,
    disablePadding: false,
    label: 'Mã báo giá',
    align: 'center',
  },
  {
    id: 'createdBy',
    numeric: true,
    disablePadding: false,
    label: 'Người tạo',
    align: 'center',
  },
  {
    id: 'clientCompanyName',
    numeric: true,
    disablePadding: false,
    label: 'Khách hàng',
    align: 'center',
    width: '200px',
  },
  {
    id: 'arrService',
    numeric: true,
    disablePadding: false,
    label: 'Dịch vụ',
    align: 'center',
    render: (row) => row?.arrService?.map((item) => item?.name).join(', '),
  },
  {
    id: 'quotedate',
    numeric: true,
    disablePadding: false,
    label: 'Ngày báo giá',
    align: 'center',
    render: (row) => Utils.formatDateFn(row?.quotedate),
  },
];
