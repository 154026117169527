import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ApiServices from 'services';

const BasicTable = () => {
  const [data, setData] = React.useState([]);

  const getInitData = async () => {
    try {
      const { data } = await ApiServices.getCountClientCareHistoryByUserCode();
      setData(data);
    } catch (error) {
      console.warn('ERROR - get init data', error);
    }
  };
  React.useEffect(() => {
    getInitData();
  }, []);
  return (
    <TableContainer component={Paper} sx={{ mt: 3 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Nhân Viên</TableCell>
            <TableCell align="center">Lead Đang CS</TableCell>
            <TableCell align="center">Deal</TableCell>
            <TableCell align="center">Lead Chờ CS lại</TableCell>
            <TableCell align="center">Deal Chờ CS lại</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row?._id || 'undefine'}
              </TableCell>
              <TableCell align="center">{row?.leadTakingCare}</TableCell>
              <TableCell align="center">{row?.deal}</TableCell>
              <TableCell align="center">{row?.leadWaitingForCare}</TableCell>
              <TableCell align="center">{row.leadWaitingForCare}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default BasicTable;
