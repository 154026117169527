import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  MenuItem,
  Menu,
  AppBar as MuiAppBar,
  Divider,
  ListItemIcon,
  Avatar,
} from '@mui/material';
import {
  Menu as MenuIcon,
  // Search as SearchIcon,
  AccountCircle,
  Mail as MailIcon,
  Notifications as NotificationsIcon,
  More as MoreIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Logout,
  Settings,
  PersonAdd,
} from '@mui/icons-material';
import { MdAlternateEmail } from 'react-icons/md';
import { AiOutlineKey } from 'react-icons/ai';
import { BsImage } from 'react-icons/bs';
import { FiKey } from 'react-icons/fi';
import LightBox from 'react-image-lightbox';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as appActions from 'store/reducers/app/action';
import storage from 'storage';
import ApiServices from 'services';
// import { Search, SearchIconWrapper, StyledInputBase } from './search';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
function Header({ isOpen, open, close, ...props }) {
  const txtField = React.useRef(null);
  const dispatch = useDispatch();
  let history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [isShowAvatar, setIsShowAvatar] = React.useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const onChangePasswordFn = () => {
    history.push('/change-password');
    handleMenuClose();
  };

  const onChangeEmailFn = () => {
    history.push('/change-email');
    handleMenuClose();
  };

  const showAvatarFn = () => {
    handleMenuClose();
    setIsShowAvatar(true);
  };

  const onChangeAvatarFn = async (event) => {
    try {
      const { data } = await ApiServices.changeAvatar(storage?.user?.Id, {
        file: event.target.files[0],
      });
      if (data) {
        dispatch(appActions.signIn({ ...storage?.user, UrlAvatar: data }));
        handleMenuClose();
        window.UIMessage?.success('Cập nhật thành công');
      }
    } catch (err) {
      console.warn('import excel - Change Avatar', err);
      window.UIMessage?.error('Cập nhật thất bại !!!');
    }
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 2,
            right: 16,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={showAvatarFn}>
        <ListItemIcon>
          <BsImage />
        </ListItemIcon>
        Xem ảnh đại diện
      </MenuItem>
      <MenuItem
        onClick={() => {
          txtField.current.click();
        }}
      >
        <Avatar sx={{ width: 20, height: 20 }} />
        <span>
          Đổi ảnh đại diện
          <input accept="image/*" ref={txtField} type="file" hidden onChange={onChangeAvatarFn} />
        </span>
      </MenuItem>
      {/* <MenuItem onClick={handleMenuClose}>
        <Avatar sx={{ width: 30, height: 30 }} /> My account
      </MenuItem> */}
      <MenuItem onClick={onChangePasswordFn}>
        <ListItemIcon>
          <FiKey />
        </ListItemIcon>
        Đổi mật khẩu
      </MenuItem>
      <MenuItem onClick={onChangeEmailFn}>
        <ListItemIcon>
          <MdAlternateEmail />
        </ListItemIcon>
        Cập nhật Email
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => history.push('/login')}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        Đăng Xuất
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 4 new mails"
          color="inherit"
          onClick={() => history.push('/chat')}
        >
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Hồ Sơ Người Dùng</p>
      </MenuItem>
    </Menu>
  );

  return (
    <AppBar
      position="fixed"
      open={isOpen}
      sx={{ bgcolor: 'background.default', boxShadow: 'none' }}
    >
      <Toolbar>
        <IconButton onClick={isOpen ? close : open}>
          {isOpen ? (
            <ChevronLeftIcon
              sx={{
                backgroundColor: 'background.paper',
                borderRadius: '50%',
                color: 'primary.main',
                ml: '-15px',
              }}
            />
          ) : (
            <ChevronRightIcon
              sx={{
                ml: '60px',
                backgroundColor: 'background.paper',
                borderRadius: '50%',
                color: 'primary.main',
              }}
            />
          )}
        </IconButton>
        <Typography variant="h7" component="div" sx={{ color: 'text.primary' }}>
          Xin chào, <b>{storage?.user?.Name || '-'}</b>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          {/* <IconButton
            size="large"
            aria-label="show 4 new mails"
            onClick={() => history.push('/chat')}
          >
            <Badge badgeContent={4} color="error">
              <MailIcon />
            </Badge>
          </IconButton> */}
          <IconButton size="large" aria-label="show 17 new notifications">
            <Badge badgeContent={17} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
          >
            {storage.user.UrlAvatar === '' ? (
              // <AccountCircle sx={{ width: 30, height: 30 }} />
              <Avatar sx={{ width: 30, height: 30 }} />
            ) : (
              <Avatar
                alt={storage?.user?.Code}
                src={`${process.env.REACT_APP_API_URL}/${storage?.user?.UrlAvatar}`}
                sx={{ width: 30, height: 30 }}
              />
            )}
          </IconButton>
        </Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit"
          >
            <MoreIcon />
          </IconButton>
        </Box>
      </Toolbar>
      {renderMobileMenu}
      {renderMenu}
      {isShowAvatar && (
        <LightBox
          mainSrc={`${process.env.REACT_APP_API_URL}/${storage?.user?.UrlAvatar}`}
          onCloseRequest={() => setIsShowAvatar(false)}
          closeLabel="Đóng"
        />
      )}
    </AppBar>
  );
}

export { Header };
