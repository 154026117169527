import * as React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import {
  Box,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Toolbar,
  Typography,
  Link,
  Button,
  Avatar,
  Stack,
} from '@mui/material';

import {
  BsFillCaretLeftFill,
  BsFillCaretRightFill,
  BsFillCaretUpFill,
  BsTrash,
} from 'react-icons/bs';
import moment from 'moment';
import { LoadingView } from '../loading-view';
import { visuallyHidden } from '@mui/utils';
import { TablePaginationActions } from './table-pagination-actions';
import { TableToolbar } from './table-toolbar';
import { styled } from '@mui/material/styles';

function labelDisplayedRowsFn({ from, to, count }) {
  return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`} items`;
}
//=================================================================

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  if (!array?.length) return;

  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const BORDER_TABLE_CELL = '1px solid rgba(0, 0, 255, 0.404)';

function EnhancedTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  headCells,
  rowsPerPageCustom = [10, 20, 100],
  ...props
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* =================== CheckBox ================================ */}
        {/* <TableCell
          padding="checkbox"
          sx={{
            backgroundColor: '#FFFFFF',
            // borderTop: '1px solid #EAEAEA',
            // borderBottom: '1px solid #EAEAEA',
            // boxSizing: 'border-box',
            color: '#343A40',
            padding: '2px',
          }}
        >
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}

        {headCells?.map((headCell, indexHeader) => {
          const tmpStyle = {};
          if (headCell.fixed) {
            tmpStyle.position = 'sticky';
            tmpStyle[headCell.fixed] = 0;
            tmpStyle.padding = '8px';
          }
          // control border Table Cell for Table
          if (indexHeader === 0) {
            tmpStyle.border = BORDER_TABLE_CELL;
            tmpStyle.borderTopLeftRadius = '12px';
          } else {
            tmpStyle.borderTop = BORDER_TABLE_CELL;
            tmpStyle.borderRight = BORDER_TABLE_CELL;
            tmpStyle.borderBottom = BORDER_TABLE_CELL;
            if (indexHeader === headCells?.length - 1) {
              tmpStyle.borderTopRightRadius = '12px';
            }
          }
          tmpStyle.textAlign = headCell?.alignTitle || 'center';
          return (
            <TableCell
              key={`Cell-${headCell.id}`}
              align="left"
              // padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={headCell?.sort && orderBy === headCell.id ? order : false}
              sx={{
                minWidth: headCell?.width || 150,
                backgroundColor: '#FFFFFF',
                color: '#343A40',
                // textAlign: 'center',
                justifyContent: 'flex-start',
                // justifyContent: 'flex-start',
                padding: '8px',
                ...tmpStyle,
              }}
            >
              {!headCell?.sort ? (
                <div>{headCell.label}</div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <div style={{ flex: 1 }}>{headCell.label}</div>
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                    IconComponent={BsFillCaretUpFill}
                  >
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </div>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export const Table = ({
  rows = [],
  headCells = [],
  totalRecords,
  onPageChange,
  sizeTable = 'small',
  isHeader = true,
  filter,
  defaultPage = 10,
  ...props
}) => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultPage);

  React.useEffect(() => {
    if (filter?.pageNumber) {
      setPage(filter?.pageNumber - 1);
    }
    if (filter?.pageSize) {
      setRowsPerPage(filter?.pageSize);
    }
  }, [filter]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    props?.onSelectedChange && props.onSelectedChange(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange &&
      onPageChange({
        pageNumber: newPage + 1,
        pageSize: rowsPerPage,
      });
  };

  const handleChangeRowsPerPage = (event) => {
    const pageSize = event.target.value;
    setRowsPerPage(parseInt(pageSize, 10));
    setPage(0);
    onPageChange &&
      onPageChange({
        pageNumber: 0,
        pageSize: pageSize,
      });
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const renderRows = (row, index) => {
    {
      const isItemSelected = isSelected(row.id);
      const labelId = `enhanced-table-checkbox-${page}-${index}`;
      return (
        <TableRow
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.id}
          selected={isItemSelected}
          sx={{
            backgroundColor: '#FFF',
            '&.MuiTableRow-hover:hover .mui-cell-fixed': {
              backgroundColor: '#DFF2FF',
            },
            '&.MuiTableRow-hover:hover': {
              backgroundColor: '#DFF2FF',
            },
            '&.MuiTableRow-root.Mui-selected': {
              backgroundColor: '#DFF2FF',
            },
            '&.MuiTableRow-root.Mui-selected .mui-cell-fixed': {
              backgroundColor: '#DFF2FF',
            },
            '&.MuiTableRow-root.MuiTableCell': {
              backgroundColor: 'red',
            },
          }}
        >
          {/* =================== CheckBox ================================ */}
          {/* <TableCell padding="checkbox" onClick={(event) => handleClick(event, row.id)}>
            <Checkbox
              color="primary"
              checked={isItemSelected}
              inputProps={{
                'aria-labelledby': labelId,
              }}
            />
          </TableCell> */}
          {/* <TableCell padding="checkbox">{index + 1}</TableCell> */}

          {headCells?.map((item, index) => {
            const tmpStyle = {};
            if (item.fixed) {
              tmpStyle.backgroundColor = 'fff';
              tmpStyle.position = 'sticky';
              tmpStyle[item.fixed] = 0;
              tmpStyle.backgroundColor = 'background.paper';
            }
            tmpStyle.padding = '8px';
            tmpStyle.fontSize = '12px';
            // control border Table Cell for Table
            if (index === 0) {
              tmpStyle.borderLeft = BORDER_TABLE_CELL;
              tmpStyle.borderBottom = BORDER_TABLE_CELL;
              tmpStyle.borderRight = BORDER_TABLE_CELL;
            } else {
              tmpStyle.borderBottom = BORDER_TABLE_CELL;
              tmpStyle.borderRight = BORDER_TABLE_CELL;
            }

            if (index === 0) {
              tmpStyle.textAlign = item?.align || 'center';
              return (
                <TableCell
                  key={`${item.id}-${row.id}`}
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                  sx={tmpStyle}
                >
                  {renderFirstItem(item, row)}
                </TableCell>
              );
            }

            return (
              <TableCell
                className={item.fixed ? 'mui-cell-fixed' : 'mui-cell'}
                sx={tmpStyle}
                key={`${item.id}-${row.id}`}
                align={item?.align || 'center'}
              >
                {item.render
                  ? item.render(row)
                  : item?.isDateFormat && row[item.id]
                  ? moment(row[item.id]).format('DD/MM/YYYY')
                  : row[item.id]}
              </TableCell>
            );
          })}
        </TableRow>
      );
    }
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const renderFirstItem = (item, row) => {
    if (props?.onClickDetail) {
      return (
        <Link
          underline="hover"
          sx={{ cursor: 'pointer' }}
          onClick={() => props?.onClickDetail(row)}
        >
          {item.render ? item.render(row) : row[item.id]}
        </Link>
      );
    }
    return item.render ? item.render(row) : row[item.id] || 'underfine';
  };

  return (
    <>
      <Paper sx={{ width: '100%', position: 'relative' }}>
        <LoadingView loading={props?.loading} />
        <Box>
          <Typography
            sx={{ flex: '1 1', fontWeight: 'bold', marginLeft: '20px', paddingTop: '5px' }}
            variant="h7"
            id="tableTitle"
            component="div"
          >
            {props?.title}
          </Typography>
        </Box>
        {/* =================== Heder ================================ */}
        {isHeader && (
          <TableToolbar
            numSelected={selected.length}
            render={props?.renderHeader}
            renderSelect={props?.renderSelect}
          />
        )}
        <TableContainer
          sx={{
            maxHeight: 'calc(100vh - 235px)',
          }}
        >
          <MuiTable
            sx={{ minWidth: 500 }}
            stickyHeader
            aria-labelledby="tableTitle"
            size={sizeTable}
          >
            <EnhancedTableHead
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {!onPageChange
                ? stableSort(rows, getComparator(order, orderBy))
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((row, index) => renderRows(row, index))
                : stableSort(rows, getComparator(order, orderBy))?.map((row, index) =>
                    renderRows(row, index),
                  )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: props.isDense ? 33 * emptyRows : 'auto',
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </MuiTable>
        </TableContainer>
        <TablePaginationCustom
          rowsPerPageOptions={[10, 20, 100]}
          component="span"
          count={totalRecords ? totalRecords : rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          labelRowsPerPage="Số dòng"
          showFirstButton={false}
        />
      </Paper>
    </>
  );
};

export const TablePaginationCustom = styled(TablePagination)(({ theme }) => ({
  width: '100vw',
  marginLeft: 0,
  padding: 0,
  maxHeight: 'calc(100vh - 350px)',
  '& .MuiTablePagination-selectLabel': {},
  '& .MuiTablePagination-displayedRows': {
    display: 'none',
  },
  '.MuiTablePagination-select': {
    borderRadius: '4px',
    border: '1px solid #DFDFDF',
  },
  '.MuiInputBase-colorPrimary': {
    margin: '10px',
  },
}));
