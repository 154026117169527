import React from 'react';
import { Box, IconButton } from '@mui/material';
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import Collapse from '@mui/material/Collapse';
import { SERVICES_ID } from 'commons/constants';
import { FreightControlQuoteForm } from '../forms-control/freight-control-quote-form';
import { TruckingControlQuoteForm } from '../forms-control/trucking-control-quote-form';
import { CustomsControlQuoteForm } from '../forms-control/customs-control-quote-form';
import { WarehouseControlQuoteForm } from '../forms-control/warehouse-control-quote-form';
import { MergeCustomsAndTruckingForm } from '../forms-control/merge-customs-and-trucking-form';

const ServiceItem = ({ item }) => {
  const { FREIGHT, TRUCKING, CUSTOMS, WAREHOUSE } = SERVICES_ID;
  const [open, setOpen] = React.useState(false);

  const handleOpenItem = () => {
    setOpen(!open);
  };

  const renderServiceItem = (item) => {
    if (item?.objQuotes?.isGroup === 1) {
      return (
        <MergeCustomsAndTruckingForm
          dataTrucking={item?.objTrucking}
          dataCustoms={item?.objCustoms}
        />
      );
    }
    switch (item?.objQuotes?.arrServiceId[0]) {
      case FREIGHT:
        return <FreightControlQuoteForm dataFreight={item?.objFreight} />;
      case TRUCKING:
        return <TruckingControlQuoteForm dataTrucking={item?.objTrucking} />;
      case CUSTOMS:
        return <CustomsControlQuoteForm dataCustoms={item?.objCustoms} />;
      case WAREHOUSE:
        return <WarehouseControlQuoteForm dataWarehouse={item?.objWarehouse} />;

      default:
        return;
    }
  };

  return (
    <Box
      sx={{
        height: 'fit-content',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: '12px',
        marginBottom: '10px',
        padding: '10px',
      }}
    >
      <Box>
        <IconButton aria-label="expand row" size="small" onClick={handleOpenItem}>
          {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </IconButton>
        <b style={{ color: 'blue' }}>
          {item?.objQuotes?.arrService?.map((item) => item?.name)?.join(', ')} {'  '}
        </b>
        <i style={{ color: 'red' }}>({item?.objQuotes?.code})</i>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {renderServiceItem(item)}
      </Collapse>
    </Box>
  );
};

export { ServiceItem };
