import * as React from 'react';
import { Grid, RadioGroup, Radio, FormControlLabel, Box, FormLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TextInput, Selection, ComboBox } from 'components';
import ApiServices from 'services';
import { ARISING_TYPE } from 'commons/constants';

const ARISING_TYPE_OPTIONS = [
  { value: 0, content: ARISING_TYPE[0] },
  { value: 1, content: ARISING_TYPE[1] },
];

export const ContentComplaint = ({
  errors,
  handleBlur,
  handleChange,
  touched,
  values,
  users,
  verifier,
  onHandleChangeverifier,
  mainFollowers,
  onHandleChangeMainFollowers,
  proposer,
  onHandleChangeProposer,
  approvedby,
  onHandleChangApprovedby,
  handlerCode,
  onHandleChangeHandlerCode,
  checker,
  onHandleChangeChecker,
}) => {
  return (
    <>
      <Box
        sx={{
          marginRight: '10px',
        }}
      >
        {/* Row 1 */}
        <BoxRow>
          <TextInput
            error={Boolean(touched.requestVerification && errors.requestVerification)}
            helperText={touched.requestVerification && errors.requestVerification}
            label="Nhập xác minh khiếu nại"
            name="requestVerification"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.requestVerification}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormLabel id="rate-verification">Đánh giá:</FormLabel>
            <RadioGroup
              onChange={handleChange}
              value={values.rateVerification}
              row
              aria-labelledby="rate-verification"
              name="rateVerification"
              sx={{ justifyContent: 'flex-end', marginLeft: 'auto' }}
            >
              <FormControlLabel value={1} control={<Radio />} label="Hợp lý" />
              <FormControlLabel value={0} control={<Radio />} label="Không hợp lý" />
            </RadioGroup>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <ComboBox
                options={users}
                label="Người xác minh"
                sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
                keyField="name"
                value={verifier}
                onChange={(event, newValue) => onHandleChangeverifier(event, newValue)}
              />
            </Grid>
            <Grid item xs={5}>
              <TextInput
                error={Boolean(touched.verificationDate && errors.verificationDate)}
                helperText={touched.verificationDate && errors.verificationDate}
                label="Ngày xác minh"
                name="verificationDate"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.verificationDate}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </BoxRow>
        {/* Row 2 */}
        <BoxRow>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Selection
                error={Boolean(touched.responsibleEntityId && errors.responsibleEntityId)}
                helperText={touched.responsibleEntityId && errors.responsibleEntityId}
                label="Đ.vị chịu trách nhiệm giải quyết"
                name="responsibleEntityId"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.responsibleEntityId}
                syncData={ApiServices.getDepartments}
              />
            </Grid>
            <Grid item xs={6}>
              <ComboBox
                options={users}
                label="Người theo dõi chính"
                sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
                keyField="name"
                value={mainFollowers}
                onChange={(event, newValue) => onHandleChangeMainFollowers(event, newValue)}
              />
            </Grid>
          </Grid>
        </BoxRow>
        {/* Row 3 */}
        <BoxRow>
          <TextInput
            error={Boolean(touched.solution && errors.solution)}
            helperText={touched.solution && errors.solution}
            label="Đề xuất hướng giải quyết"
            name="solution"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.solution}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextInput
                error={Boolean(touched.approvedNote && errors.approvedNote)}
                helperText={touched.approvedNote && errors.approvedNote}
                label="Phê duyệt"
                name="approvedNote"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.approvedNote}
              />
            </Grid>
            <Grid item xs={6}>
              <ComboBox
                options={users}
                label="Người đề xuất"
                sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
                keyField="name"
                value={proposer}
                onChange={(event, newValue) => onHandleChangeProposer(event, newValue)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ComboBox
                options={users}
                label="Người phê duyệt"
                sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
                keyField="name"
                value={approvedby}
                onChange={(event, newValue) => onHandleChangApprovedby(event, newValue)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                error={Boolean(touched.approvedOn && errors.approvedOn)}
                helperText={touched.approvedOn && errors.approvedOn}
                label="Ngày phê duyệt"
                name="approvedOn"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.approvedOn}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </BoxRow>
        {/* Row 4 */}
        <BoxRow>
          <TextInput
            required
            error={Boolean(touched.contentsProcessed && errors.contentsProcessed)}
            helperText={touched.contentsProcessed && errors.contentsProcessed}
            label="Nhập tiến hành xử lý"
            name="contentsProcessed"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.contentsProcessed}
          />
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <ComboBox
                options={users}
                label="Người xử lý"
                sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
                keyField="name"
                value={handlerCode}
                onChange={(event, newValue) => onHandleChangeHandlerCode(event, newValue)}
              />
            </Grid>
            <Grid item xs={5}>
              <TextInput
                error={Boolean(touched.processingDate && errors.processingDate)}
                helperText={touched.processingDate && errors.processingDate}
                label="Ngày xử lý"
                name="processingDate"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.processingDate}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </BoxRow>
        {/* Row 5 */}
        <BoxRow>
          <TextInput
            required
            error={Boolean(touched.checkNote && errors.checkNote)}
            helperText={touched.checkNote && errors.checkNote}
            label="Kiểm tra"
            name="checkNote"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.checkNote}
          />
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <ComboBox
                options={users}
                label="Người kiểm tra"
                sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
                keyField="name"
                value={checker}
                onChange={(event, newValue) => onHandleChangeChecker(event, newValue)}
              />
            </Grid>
            <Grid item xs={5}>
              <TextInput
                error={Boolean(touched.checkDate && errors.checkDate)}
                helperText={touched.checkDate && errors.checkDate}
                label="Ngày kiểm tra"
                name="checkDate"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.checkDate}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </BoxRow>
        {/* Row 6 */}
        <BoxRow>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Selection
                error={Boolean(touched.arisingtype && errors.arisingtype)}
                helperText={touched.arisingtype && errors.arisingtype}
                label="Loại phát sinh"
                name="arisingtype"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.arisingtype}
                dataArrObject={ARISING_TYPE_OPTIONS}
              />
            </Grid>
            <Grid item xs={5}>
              <TextInput
                required
                error={Boolean(touched.moneyArising && errors.moneyArising)}
                helperText={touched.moneyArising && errors.moneyArising}
                label="Nhập chi phí"
                name="moneyArising"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.moneyArising}
              />
            </Grid>
          </Grid>
        </BoxRow>
        {/* Row 7 */}
        <BoxRow>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormLabel id="demo-row-radio-buttons-group-label">Khách hàng có hài lòng:</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              sx={{ justifyContent: 'flex-end', marginLeft: 'auto' }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Có" />
              <FormControlLabel value={false} control={<Radio />} label="Không" />
            </RadioGroup>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Cần thiết khắc phục phòng ngừa:
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              sx={{ justifyContent: 'flex-end', marginLeft: 'auto' }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Có" />
              <FormControlLabel value={false} control={<Radio />} label="Không" />
            </RadioGroup>
          </Box>
          <TextInput
            required
            error={Boolean(touched.reasonOfRating && errors.reasonOfRating)}
            helperText={touched.reasonOfRating && errors.reasonOfRating}
            label="Lý do"
            name="reasonOfRating"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.reasonOfRating}
          />
        </BoxRow>
      </Box>
    </>
  );
};

const BoxRow = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'rgb(234, 238, 243)',
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
  borderRadius: '10px',
  fontSize: '12px',
  padding: '10px',
  marginBottom: '10px',
}));
