import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[800],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

function Popup({ title, open, closePopup, body }) {
  return (
    <Dialog open={open} onClose={closePopup}>
      {/* <DialogTitle>{title}</DialogTitle> */}
      <BootstrapDialogTitle id="customized-dialog-title" onClose={closePopup}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent>{body}</DialogContent>
    </Dialog>
  );
}

export { Popup };
