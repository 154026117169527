import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
// pages for this product
import { LoginPage } from './login-page';
import { ErrorPage } from './errors-page';
import { HomePage } from './home-page';
import { Department } from './department-page';
import { Companies as CompaniesPage } from './companies-page';
import { Layout } from 'components';
import { PrivateRoute, PublicRoute } from './routes-handler';
import { ClientSources } from './client-sources-page';
import { Position } from './positions-page';
import { ClientGroups } from './client-groups-page';
import { ClientCompanies } from './client-companies-page';
import { ClientStatus } from './client-status-page';
import { User } from './users-page';
import { Clients } from './clients-page';
import { ClientCareProcess } from './client-care-process-page';
import { ClientComplaints } from './client-complaints-page';
import { Services } from './services-page';
import { Employees } from './employees-page';
import { LoyaltyTypes } from './loyalty-types-page';
import { Contracts } from './contracts-page';
import { Documents } from './documents-page';
import { Mails } from './mails-page';
import { ClientCareHistory } from './client-care-history-page';
import { ScheduleManageMent } from './schedule-management-page';
import { Quotes } from './quote-page';
import { PermissionMenu } from './permission-menu';
import { PermissionSysFunc } from './permission-system-function';
import { PermissionMemberGroup } from './permission-membergroup';
import { Chat } from './chat-page';
import { ScheduleUser } from './schedule-user-page';
import { ClientTrashes } from './client-trashes-page';
import { ChangePassword } from './users-page/change-password';
import { ChangeEmail } from './users-page/change-email';
import { NewLead } from './new-leads-page';
import { ClientsHolding } from './client-holding';
import { ClientRating } from './client-rating';
import { LinkInviteGroupByToken } from './invite-group-by-token';
import { PermissionExecute } from './permission-execute';
import { AreaPage } from './area-page';
import { CountryPage } from './country-page';
import { CarrierPage } from './carrier-page';
import { CurrencyPage } from './currency-page';
import { LocationVTPage } from './location-vt-page';
import { PortPage } from './port-page';
import { CargoTypePage } from './cargo-type-page';
import { UnitPage } from './unit-page';
import { SubServicePage } from './sub-service-page';
import { ContTypePage } from './cont-type-page';
import { SubFeeGroupPage } from './sub-fee-group-page';
import { ContractTypePage } from './contract-type-page';
import { LeaseSpacePage } from './lease-space-page';
import { PackingAirPage } from './packing-air-page';
import { VendorPage } from './vendor-page';
import { SetupSurchargePage } from './setup-surcharge-page';
import { FreightPriceBoard } from './price-board/freight-price-board';
import { CustomsPriceBoard } from './price-board/customs-price-board';
import { TruckingPriceBoard } from './price-board/trucking-price-board';
import { WarehousePriceBoard } from './price-board/warehouse-price-board';
import { CustomerQuotes } from './customer-quotes-page';
import { ContractsManagement } from './contracts-management';
import { ClientSupportBoard } from './client-support-board';
import { ColoaderPage } from './coloader-page';
import { DeliveryMethod } from './delivery-method-page';
// ==================   API NEW Start From 22/Feb/24  =====================
import { TitleManagement } from './title-management';
// ==================   API NEW Start From 01/06/2024  =====================
import { VendorList } from './vendor-list';

export default function routes() {
  return (
    <Switch>
      <PrivateRoute exact path="/" component={HomePage} />
      <PrivateRoute exact path="/companies" component={CompaniesPage} />
      <PrivateRoute exact path="/client-sources" component={ClientSources} />
      <PrivateRoute exact path="/departments" component={Department} />
      <PrivateRoute exact path="/positions" component={Position} />
      <PrivateRoute exact path="/client-groups" component={ClientGroups} />
      <PrivateRoute exact path="/client-companies" component={ClientCompanies} />
      <PrivateRoute exact path="/client-status" component={ClientStatus} />
      <PrivateRoute exact path="/users" component={User} />
      <PrivateRoute exact path="/clients" component={Clients} />

      <PrivateRoute exact path="/client-care-process" component={ClientCareProcess} />
      <PrivateRoute exact path="/client-complaints" component={ClientComplaints} />
      <PrivateRoute exact path="/services" component={Services} />
      <PrivateRoute exact path="/employees" component={Employees} />
      <PrivateRoute exact path="/loyalty-types" component={LoyaltyTypes} />
      <PrivateRoute exact path="/contracts" component={Contracts} />
      <PrivateRoute exact path="/documents" component={Documents} />
      <PrivateRoute exact path="/mails" component={Mails} />
      <PrivateRoute exact path="/client-care-history" component={ClientCareHistory} />
      <PrivateRoute exact path="/schedule-management" component={ScheduleManageMent} />
      <PrivateRoute exact path="/quotes" component={Quotes} />

      <PrivateRoute exact path="/permission-menu" component={PermissionMenu} />
      <PrivateRoute exact path="/permission-member-group" component={PermissionMemberGroup} />
      <PrivateRoute exact path="/permission-system-function" component={PermissionSysFunc} />
      <PrivateRoute exact path="/execute_permission_functions" component={PermissionExecute} />

      <PrivateRoute exact path="/chat" component={Chat} />
      <PrivateRoute exact path="/schedule-user" component={ScheduleUser} />
      <PrivateRoute exact path="/client-trashes" component={ClientTrashes} />
      <PrivateRoute exact path="/change-password" component={ChangePassword} />
      <PrivateRoute exact path="/change-email" component={ChangeEmail} />
      <PrivateRoute exact path="/new-leads" component={NewLead} />
      <PrivateRoute exact path="/client-holding" component={ClientsHolding} />

      <PrivateRoute exact path="/client-rating" component={ClientRating} />
      <PrivateRoute exact path="/chat/invite-group-by-token" component={LinkInviteGroupByToken} />

      <PrivateRoute exact path="/area-page" component={AreaPage} />
      <PrivateRoute exact path="/country-page" component={CountryPage} />
      <PrivateRoute exact path="/carrier-page" component={CarrierPage} />
      <PrivateRoute exact path="/currency-page" component={CurrencyPage} />
      <PrivateRoute exact path="/location-vt-page" component={LocationVTPage} />
      <PrivateRoute exact path="/port-page" component={PortPage} />
      <PrivateRoute exact path="/cargo-type-page" component={CargoTypePage} />
      <PrivateRoute exact path="/unit-page" component={UnitPage} />
      <PrivateRoute exact path="/sub-service-page" component={SubServicePage} />

      <PrivateRoute exact path="/cont-type-page" component={ContTypePage} />
      <PrivateRoute exact path="/sub-fee-group-page" component={SubFeeGroupPage} />
      <PrivateRoute exact path="/contract-type-page" component={ContractTypePage} />
      <PrivateRoute exact path="/vendor-page" component={VendorPage} />
      <PrivateRoute exact path="/lease-space-page" component={LeaseSpacePage} />
      <PrivateRoute exact path="/packing-air-page" component={PackingAirPage} />
      <PrivateRoute exact path="/setup-surcharge-page" component={SetupSurchargePage} />

      <PrivateRoute exact path="/coloader-page" component={ColoaderPage} />
      <PrivateRoute exact path="/delivery-method" component={DeliveryMethod} />

      {/* Price Board - Bảng Giá */}
      <PrivateRoute exact path="/freight-price-board" component={FreightPriceBoard} />
      <PrivateRoute exact path="/custom-of-price-board" component={CustomsPriceBoard} />
      <PrivateRoute exact path="/trucking-price-board" component={TruckingPriceBoard} />
      <PrivateRoute exact path="/warehouse-price-board" component={WarehousePriceBoard} />
      {/* Customer Quotes - Báo giá */}
      <PrivateRoute exact path="/customer-quotes" component={CustomerQuotes} />
      <PrivateRoute exact path="/contracts-management" component={ContractsManagement} />
      <PrivateRoute exact path="/client-support-board" component={ClientSupportBoard} />
      {/* ==================   API NEW Start From 22/Feb/24  ===================== */}
      <PrivateRoute exact path="/title-management" component={TitleManagement} />
      {/* ==================   API NEW Start From 19/Sep/24  ===================== */}
      <PrivateRoute exact path="/vendor-list" component={VendorList} />

      <Route path="/login" exact component={LoginPage} />
      <Route path="*" component={ErrorPage} />
    </Switch>
  );
}
