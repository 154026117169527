import { styled } from '@mui/material/styles';
import { Box, TextField } from '@mui/material';

export const ContainerPrice = styled(Box)(({ theme }) => ({
  maxWidth: '100vw',
  overflowX: 'auto',
}));
export const ContainerSumPrice = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '10px',
  marginTop: '15px',
}));
export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '120px',
  marginLeft: '10px',
}));
export const ContainerLeft = styled(Box)(({ theme }) => ({
  width: '300px',
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'right',
}));
export const ContainerBody = styled(Box)(({ theme }) => ({
  // width: width,
  display: 'flex',
  flexDirection: 'row',
}));
export const ContainerMainPrice = styled(Box)(({ theme, isSeaLcl }) => ({
  display: 'flex',
  flexDirection: isSeaLcl ? 'row' : 'column',
}));
export const ContainerItemSum = styled(Box)(({ theme }) => ({
  marginBottom: '15px',
  display: 'flex',
  flexDirection: 'row',
}));
export const ContainerLabelSum = styled(Box)(({ theme }) => ({
  marginBottom: '10px',
  display: 'flex',
  flexDirection: 'row',
  height: '40px',
}));
export const ContainerItemService = styled(Box)(({ theme }) => ({
  height: 'fit-content',
  backgroundColor: 'rgba(6, 95, 212, 0.1)',
  borderRadius: '12px',
  display: 'flex',
  marginBottom: '10px',
  marginTop: '5px',
  padding: '10px 20px',
}));
