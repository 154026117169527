import * as React from 'react';
import { TextField, FormControl, InputLabel } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import moment from 'moment';

export const DatePicker = ({
  label,
  inputFormat = 'dd/MM/yyyy',
  onChange,
  value,
  name,
  type = 'text',
  size = 'small',
  margin = 'dense',
  fullWidth = true,
  variant = 'standard',
  error = false,
  helperText = '',
  ...props
}) => {
  const [dateValue, setDateValue] = React.useState(null);
  React.useEffect(() => {
    value && setDateValue(new Date(moment(value).format('YYYY-MM-DD')));
  }, [value]);
  const handleChange = (newValue) => {
    setDateValue(newValue);

    onChange &&
      onChange({
        persist: () => {},
        target: {
          type: 'change',
          name,
          value: moment(newValue).format('YYYY-MM-DD'),
        },
      });
  };
  return (
    <>
      <FormControl
        {...{
          fullWidth,
          type,
          size,
          variant,
          // margin,
        }}
        sx={{ mt: 3 }}
      >
        {/* <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel> */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            {...{
              fullWidth,
              type,
              size,
              variant,
              margin,
              variant,
              inputFormat,
              label,
            }}
            value={dateValue}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </FormControl>
      {error && (
        <div style={{ color: '#d32f2f', fontSize: '0.75rem', marginTop: '3px' }}>{helperText}</div>
      )}
    </>
  );
};
