import * as React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { TextInput, DateTimePicker } from 'components';
import { Box, Button } from '@mui/material';
import ApiServices from 'services';

const CallForm = ({
  setOpenCallForm,
  clientCareHistoryItem,
  value,
  setValue,
  idClientSupportCall,
}) => {
  const addCallFn = async (values, { setSubmitting }) => {
    setSubmitting(true);
    values.clientCareHistoryId = clientCareHistoryItem.id;
    values.clientId = idClientSupportCall;
    try {
      const { status } = await ApiServices.createCallManagement(values);
      if (status === 200) {
        window.UIMessage?.success('Thêm lịch sử cuộc gọi thành công');
        setOpenCallForm(false);
        return setValue('2');
      } else {
        window.UIMessage?.error('Thêm lịch sử cuộc gọi thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  return (
    <>
      <Formik
        initialValues={{
          callTimeFrom: '',
          callTimeTo: '',
          note: '',
        }}
        validationSchema={Yup.object().shape({
          callTimeFrom: Yup.string().max(255).required('Vui lòng chọn thời gian bắt đầu gọi !!!'),
          callTimeTo: Yup.string().max(255).required('Vui lòng chọn thời gian kết thúc gọi !!!'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          addCallFn(values, { setSubmitting });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form onSubmit={handleSubmit} style={{ width: '500px' }}>
            <DateTimePicker
              onChange={handleChange}
              value={values.callTimeFrom}
              error={Boolean(touched.callTimeFrom && errors.callTimeFrom)}
              helperText={touched.callTimeFrom && errors.callTimeFrom}
              label="Thời gian bắt đầu"
              name="callTimeFrom"
            />
            <DateTimePicker
              onChange={handleChange}
              value={values.callTimeTo}
              error={Boolean(touched.callTimeTo && errors.callTimeTo)}
              helperText={touched.callTimeTo && errors.callTimeTo}
              label="Thời gian kết thúc"
              name="callTimeTo"
            />
            <TextInput
              error={Boolean(touched.note && errors.note)}
              helperText={touched.note && errors.note}
              label="Nội dung"
              name="note"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.note}
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="small"
                type="submit"
                variant="contained"
              >
                Thêm
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export { CallForm };
