export default function () {
  return {
    container: {
      height: '100vh',
      width: '100vw',
      display: 'flex',
      padding: '5%',
      paddingTop: '5%',
      paddingBottom: '5%',
      // backgroundColor: 'rgba(99, 99, 99, 0.2)',//#21409a
      backgroundColor: '#21409a', //#21409a
    },
  };
}
