import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Drawer, Box } from '@mui/material';

export const HandleFormStep = ({ children, selected, setSelected, ...props }) => {
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (selected) {
      setOpenDrawer(true);
    } else {
      setOpenDrawer(false);
    }
  }, [selected]);
  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setSelected(null);
  };
  return (
    <div>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={onCloseDrawer}
        sx={{ zIndex: theme.zIndex.drawer + 2, mb: 5 }}
      >
        <Box
          sx={{
            p: '5px',
            pl: '10px',
            pr: '10px',
            marginTop: '5px',
            width: props?.width,
            ...props?.sx,
          }}
        >
          {children}
        </Box>
      </Drawer>
    </div>
  );
};
