import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import { TextInput } from 'components';
import Services from 'services';

export default function EmailForm({ selected, setSelected, reload }) {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const initRef = React.useRef(true);

  useEffect(() => {
    if (selected && initRef.current) {
      initRef.current = false;
      setUser(selected.userMail?.user);
      //   setPassword(selected.userMail?.password);
    }
  }, [selected]);
  const isDisabledButton = !user || password?.length < 6 || loading;
  const onSaveEmail = React.useCallback(
    async (e) => {
      e.preventDefault();

      if (loading) {
        return;
      }
      setLoading(true);
      return Services.updateEmailUser(selected.id, { user, password })
        .then((response) => {
          if (response.status == 200) {
            window.UIMessage?.success('Thao tác thành công');
            setSelected(null);
            return reload();
          }
          window.UIMessage?.error('Thao tác thất bại');
        })
        .catch((err) => {
          window.UIMessage?.error('Thao tác thất bại');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [user, password],
  );
  return (
    <form onSubmit={(e) => onSaveEmail(e)}>
      <TextInput label="Email" value={user} onChange={(e) => setUser(e.target.value)} />
      <TextInput label="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
      <Button
        variant="contained"
        sx={{ width: '100%', mt: 1 }}
        disabled={isDisabledButton}
        type="submit"
      >
        Lưu
      </Button>
    </form>
  );
}
