import React from 'react';
import Utils from 'commons/utils';
import { SearchInputColumn } from 'components';
import { RenderComplaintStatus } from 'components/custom-status';

export const Columns = (onSearchInputChange) => [
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Mã"
        onChange={({ target }) => onSearchInputChange(target.value, 'code', true)}
      />
    ),
    width: 10,
    align: 'center',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Trạng thái"
        onChange={({ target }) => onSearchInputChange(target.value, 'status', true)}
      />
    ),
    align: 'center',
    width: '100px',
    render: (row) => <RenderComplaintStatus status={row?.status} />,
  },
  {
    id: 'clientName',
    numeric: true,
    disablePadding: false,
    align: 'center',
    label: (
      <SearchInputColumn
        title="Tên khách Hàng"
        onChange={({ target }) => onSearchInputChange(target.value, 'clientName', true)}
      />
    ),
    width: '150px',
  },
  {
    id: 'clientCompanyName',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Công ty KH"
        onChange={({ target }) => onSearchInputChange(target.value, 'clientCompanyName', true)}
      />
    ),
    align: 'left',
    width: '200px',
  },
  {
    id: 'causeofComplaint',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Nguyên nhân"
        onChange={({ target }) => onSearchInputChange(target.value, 'causeofComplaint', true)}
      />
    ),
    align: 'center',
    width: '150px',
  },
  {
    id: 'problemlevel',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Cấp độ vấn đề"
        onChange={({ target }) => onSearchInputChange(target.value, 'problemlevel', true)}
      />
    ),
    align: 'center',
    width: '150px',
  },
  {
    id: 'solution',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Hướng giải quyết"
        onChange={({ target }) => onSearchInputChange(target.value, 'solution', true)}
      />
    ),
    align: 'center',
    width: '150px',
  },
  {
    id: 'approvedby',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Người duyệt"
        onChange={({ target }) => onSearchInputChange(target.value, 'approvedby', true)}
      />
    ),
    align: 'center',
    width: '150px',
  },
  {
    id: 'approvedOn',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Ngày duyệt"
        onChange={({ target }) => onSearchInputChange(target.value, 'approvedOn', true)}
      />
    ),
    align: 'center',
    width: '100px',
    render: (row) => Utils.formatDateFn(row.approvedOn),
  },
  {
    id: 'createdBy',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Người tạo"
        onChange={({ target }) => onSearchInputChange(target.value, 'createdBy', true)}
      />
    ),
    align: 'center',
    width: '150px',
  },
  {
    id: 'createdOn',
    numeric: true,
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Ngày tạo"
        onChange={({ target }) => onSearchInputChange(target.value, 'createdOn', true)}
      />
    ),
    align: 'center',
    width: '100px',
    render: (row) => Utils.formatDateFn(row.createdOn),
  },
];
