import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { SUB_SERVICES_ID, QUOTE_STATUS } from 'commons/constants';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import ApiServices from 'services';
import { QuoteFreightPriceForm } from '../../forms-management/quote-freight-price-form';
import { QuoteFreightSubChargeForm } from '../../forms-management/quote-freight-sub-charge-form';
import { HeaderFreight } from './header';
import { LoadingInContainer } from 'components';
import {
  ContainerPrice,
  ContainerSumPrice,
  StyledTextField,
  ContainerLeft,
  ContainerMainPrice,
  ContainerItemSum,
  ContainerLabelSum,
} from './styles';
const LABEL_CALC_SUM = ['Tổng giá mua:', 'Tổng % Make up:', 'Tổng giá bán:'];

const FreightControlQuoteForm = ({ dataFreight }) => {
  const dispatch = useDispatch();
  const { SEA_FCL, SEA_LCL, AIR } = SUB_SERVICES_ID;
  const [groupFeeGroup, setGroupFeeGroup] = useState([]);
  const [loading, setLoading] = useState(false);

  const isSeaFcl = useMemo(() => {
    return dataFreight?.subServiceId === SEA_FCL;
  }, [dataFreight?.subServiceId]);
  const isSeaLcl = useMemo(() => {
    return dataFreight?.subServiceId === SEA_LCL;
  }, [dataFreight?.subServiceId]);
  const isAir = useMemo(() => {
    return dataFreight?.subServiceId === AIR;
  }, [dataFreight?.subServiceId]);

  const isDisabledForm = useMemo(() => {
    if (dataFreight?.isNew) return false;
    if (dataFreight?.quotesStatus === QUOTE_STATUS.ORIGIN_QUOTE) return false;
    return true;
  }, [dataFreight]);

  const getSurchargeByFeeGroupSS = async (id) => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getSurchargeByFeeGroupSS(id, true);
      if (data) {
        const dataMapFeeGroup = await data.map((item) => {
          let ind = -1;
          if (isAir) {
            ind = dataFreight?.arrSurchargeAirFreight?.findIndex(
              (i) => i.feeGroupID === item.feeGroupID,
            );
          } else {
            ind = dataFreight?.arrSurchargeFreight?.findIndex(
              (i) => i.feeGroupID === item.feeGroupID,
            );
          }
          if (ind !== -1) {
            if (isAir) {
              item.surcharge = dataFreight?.arrSurchargeAirFreight[ind]?.surcharge || [];
            } else {
              item.surcharge = dataFreight?.arrSurchargeFreight[ind]?.surcharge || [];
            }
            item.checked = true;
          } else {
            item.checked = false;
          }

          return item;
        });
        setGroupFeeGroup(dataMapFeeGroup || []);
      }
    } catch (error) {
      console.warn('getSurchargeByFeeGroupSS:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (dataFreight?.subServiceId) {
      getSurchargeByFeeGroupSS(dataFreight?.subServiceId);
    }
  }, []);

  const calcSumPriceAndMakeUp = useMemo(() => {
    if (!dataFreight) return [];
    // filter data to calc sum by arrSurchargeFreight
    let result = [];
    if (isAir) {
      result = dataFreight?.arrSurchargeAirFreight
        ?.reduce((previousValue, currentValue) => previousValue.concat(currentValue?.surcharge), [])
        ?.reduce(
          (previousValue, currentValue) => previousValue.concat(currentValue?.arrPackingAir),
          [],
        );
    } else {
      result = dataFreight?.arrSurchargeFreight
        ?.reduce((previousValue, currentValue) => previousValue.concat(currentValue?.surcharge), [])
        ?.reduce((previousValue, currentValue) => previousValue.concat(currentValue?.arrCont), []);
    }
    // filter data arrContFreight
    if (isAir) {
      result.push(...dataFreight?.arrPackingAirFreight?.[0]?.arrPackingAir);
    } else {
      result.push(...dataFreight?.arrContFreight?.[0]?.arrCont);
    }
    // Calc sum price and make up and buy price
    if (isSeaFcl) {
      const calcSumAll = dataFreight?.contTypeCodeCheck?.map((item) => {
        const calcEachItem = result?.reduce(
          (prev, current) => {
            if (current?.contTypeCode === item) {
              const dataCal = {
                contTypeCode: prev?.contTypeCode,
                price: prev.price + current?.price,
                buyPrice: prev.buyPrice + current?.buyPrice,
              };
              return (prev = dataCal);
            }
            return prev;
          },
          {
            contTypeCode: item,
            price: 0,
            buyPrice: 0,
          },
        );
        return calcEachItem;
      }, []);
      return calcSumAll;
    }
    if (isSeaLcl) {
      const calcSumForSeaLcl = result?.reduce(
        (prev, current) => {
          const dataCal = {
            price: prev.price + current?.price,
            buyPrice: prev.buyPrice + current?.buyPrice,
          };
          return (prev = dataCal);
        },
        {
          price: 0,
          buyPrice: 0,
        },
      );
      return [calcSumForSeaLcl];
    }
    if (isAir) {
      const calcSumAllForAir = dataFreight?.packingAirCodeCheck?.map((item) => {
        const calcEachItem = result?.reduce(
          (prev, current) => {
            if (current?.packingAirCode === item) {
              const dataCal = {
                packingAirCode: prev?.packingAirCode,
                price: prev.price + current?.price,
                buyPrice: prev.buyPrice + current?.buyPrice,
              };
              return (prev = dataCal);
            }
            return prev;
          },
          {
            packingAirCode: item,
            price: 0,
            buyPrice: 0,
          },
        );
        return calcEachItem;
      }, []);
      return calcSumAllForAir;
    }
    return [];
  }, [dataFreight]);

  const renderSubChargeFreight = (item, index) => (
    <FormGroup key={item.feeGroupID + index}>
      <FormControlLabel
        sx={{ width: '30%' }}
        control={<Checkbox checked={item.checked} />}
        label={item.feeGroupName}
      />
      {item.checked && (
        <QuoteFreightSubChargeForm
          arrContType={isAir ? dataFreight?.packingAirCodeCheck : dataFreight?.contTypeCodeCheck}
          arrSurcharge={item.surchargeListFree}
          name={item.feeGroupID}
          value={item?.surcharge}
          disabled={isDisabledForm}
          isSeaLcl={isSeaLcl}
          isAir={isAir}
          keyArray={isAir ? 'arrPackingAir' : 'arrCont'} // array cont main
          keyNameOfArray={isAir ? 'packingAirCode' : 'contTypeCode'} // name of array cont
        />
      )}
    </FormGroup>
  );
  const renderSumPrice = (item, index) => (
    <ContainerMainPrice key={`${item?.contTypeCode}-${index}`} isSeaLcl={isSeaLcl}>
      <ContainerItemSum>
        <StyledTextField
          label={isSeaLcl ? 'Giá' : isAir ? item?.packingAirCode : item?.contTypeCode}
          variant="outlined"
          type="text"
          size="small"
          color="primary"
          focused
          value={new Number(item?.price).toFixed(2)}
        />
      </ContainerItemSum>
      <ContainerItemSum>
        <StyledTextField
          label={
            isAir
              ? `Makeup ${item?.packingAirCode}`
              : `% Makeup ${!isSeaLcl ? item?.contTypeCode : ''}`
          }
          variant="outlined"
          type="text"
          size="small"
          color="secondary"
          focused
          value={new Number(
            isAir
              ? item?.buyPrice - item?.price
              : ((item?.buyPrice - item?.price) / item?.price) * 100,
          ).toFixed(2)}
        />
      </ContainerItemSum>
      <ContainerItemSum>
        <StyledTextField
          label={`Giá bán ${!isSeaLcl ? (isAir ? item?.packingAirCode : item?.contTypeCode) : ''}`}
          variant="outlined"
          type="text"
          size="small"
          color="success"
          focused
          value={new Number(item?.buyPrice).toFixed(2)}
        />
      </ContainerItemSum>
    </ContainerMainPrice>
  );

  if (loading) return <LoadingInContainer />;
  return (
    <>
      <HeaderFreight dataFreight={dataFreight} />
      <b>Giá cước</b>

      <ContainerPrice>
        <QuoteFreightPriceForm
          arrContType={isAir ? dataFreight?.packingAirCodeCheck : dataFreight?.contTypeCodeCheck}
          name="arrContFreight"
          value={isAir ? dataFreight?.arrPackingAirFreight : dataFreight?.arrContFreight}
          disabled={isDisabledForm}
          isSeaLcl={isSeaLcl}
          isAir={isAir}
          keyArray={isAir ? 'arrPackingAir' : 'arrCont'} // array cont main
          keyNameOfArray={isAir ? 'packingAirCode' : 'contTypeCode'} // name of array cont
        />
        {groupFeeGroup?.map(renderSubChargeFreight)}

        <ContainerSumPrice>
          {(isSeaFcl || isAir) && (
            <ContainerMainPrice>
              {LABEL_CALC_SUM?.map((item, index) => (
                <ContainerLabelSum key={index}>
                  <ContainerLeft>
                    <i style={{ color: 'red' }}>{item}</i>
                  </ContainerLeft>
                </ContainerLabelSum>
              ))}
            </ContainerMainPrice>
          )}
          {isSeaLcl && (
            <ContainerMainPrice>
              <ContainerLabelSum>
                <ContainerLeft>
                  <i style={{ color: 'red' }}>Tổng:</i>
                </ContainerLeft>
              </ContainerLabelSum>
            </ContainerMainPrice>
          )}
          {calcSumPriceAndMakeUp?.map(renderSumPrice)}
        </ContainerSumPrice>
      </ContainerPrice>
    </>
  );
};

export { FreightControlQuoteForm };
