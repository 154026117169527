import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';

export const LoadingInContainer = () => {
  return (
    <Box
      sx={{
        left: 0,
        top: 10,
        right: 0,
        bottom: 10,
        backgroundColor: 'rgba(255,255,255,0.6)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress color="primary" disableShrink />
    </Box>
  );
};
