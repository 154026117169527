import { useState, useEffect } from 'react';
import { Checkbox, TextField, Autocomplete, Box, CircularProgress } from '@mui/material';
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from '@mui/icons-material';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const ComboBox = ({
  sx,
  multiple = false,
  size = 'small',
  options = [],
  disableCloseOnSelect = false,
  label = '',
  placeholder = '',
  value,
  onChange,
  keyField = 'name',
  limitTags,
}) => {
  return (
    <Autocomplete
      size={size}
      limitTags={limitTags}
      multiple={multiple}
      id="checkboxes-tags-demo"
      options={options}
      disableCloseOnSelect={disableCloseOnSelect}
      getOptionLabel={(option) => option[keyField] || option.code}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option[keyField] || option.code}
        </li>
      )}
      sx={sx}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder || label} />
      )}
      value={value}
      onChange={(event, newValue) => onChange(event, newValue)}
    />
  );
};
export const SimpleComboBox = ({
  sx,
  sxAutocomplete,
  multiple = false,
  size = 'small',
  disabled = false,
  disableCloseOnSelect = false,
  disableClearable = false,
  label = '',
  placeholder = '',
  limitTags = -1,
  value,
  onChange,
  keyValue = null,
  keyFieldView = null,
  renderLabel,
  renderOption,
  syncData = null,
}) => {
  const [values, setValues] = useState(multiple ? [] : null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const initData = async () => {
    if (!syncData) {
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const { data } = await syncData();
      if (data?.data) {
        setOptions(data?.data || []);
        if (value) {
          if (multiple) {
            const convertValue = value
              ?.map((item) => data?.data?.find((el) => (keyValue ? el[keyValue] : el?.id) === item))
              ?.filter((i) => i);
            setValues(convertValue);
          } else {
            const convertValue = data?.data?.find(
              (el) => (keyValue ? el[keyValue] : el?.id) === value,
            );
            setValues(convertValue);
          }
        }
      } else if (data) {
        setOptions(data || []);
        if (value) {
          if (multiple) {
            const convertValue = value
              ?.map((item) => data?.find((el) => (keyValue ? el[keyValue] : el?.id) === item))
              ?.filter((i) => i);
            setValues(convertValue);
          } else {
            const convertValue = data?.find((el) => (keyValue ? el[keyValue] : el?.id) === value);
            setValues(convertValue);
          }
        }
      }
    } catch (err) {
      console.log('ERROR - Selection initData', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initData();
  }, [syncData]);

  const handleChange = (event, newValue) => {
    setValues(newValue);
    if (multiple) {
      onChange &&
        onChange(newValue?.map((item) => (keyValue ? item[keyValue] : item?.id)) || [], newValue);
    } else {
      onChange && onChange(keyValue ? newValue[keyValue] : newValue?.id, newValue);
    }
  };

  return (
    <Box sx={sx}>
      <Autocomplete
        sx={sxAutocomplete}
        size={size}
        limitTags={limitTags}
        disabled={disabled}
        disableClearable={disableClearable}
        disableCloseOnSelect={disableCloseOnSelect}
        multiple={multiple}
        id="checkboxes-tags-demo"
        loading={loading}
        options={options}
        getOptionLabel={(option) =>
          renderLabel
            ? renderLabel(option)
            : keyFieldView
            ? option[keyFieldView]
            : option?.name || option?.code
        }
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option?.id || option?.code}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {renderOption
              ? renderOption(option)
              : option[keyFieldView] || option?.code || option?.name}
          </li>
        )}
        isOptionEqualToValue={(option, value) =>
          (keyValue ? option[keyValue] : option?.id) === (keyValue ? value[keyValue] : value?.id)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder || label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress sx={{ marginRight: 3 }} color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        value={values}
        onChange={handleChange}
      />
    </Box>
  );
};
