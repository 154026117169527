import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectQuoteId } from 'store/reducers/contract/action';
import { TableCustomContract } from 'components';
import { Box } from '@mui/material';
import ApiServices from 'services';
import { Columns } from './columns';

function QuoteManagement({ clientCompanyId }) {
  const dispatch = useDispatch();
  const [quoteList, setQuoteList] = useState([]);
  const [loading, setLoading] = useState(false);

  const initData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getPagingQuotes(
        {
          query: { pageNumber: 1, pageSize: 10 },
        },
        clientCompanyId,
      );
      if (data) {
        setQuoteList(data?.data || []);
      }
    } catch (err) {
      console.warn('initData - getPagingQuotes', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    clientCompanyId && initData();
  }, [clientCompanyId]);

  const handleSelectedChange = (row, values) => {
    console.log({ row });
    dispatch(selectQuoteId(row));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TableCustomContract
        rows={quoteList}
        headCells={Columns()}
        loading={loading}
        isHeader={false}
        isCheckbox={true}
        onSelectedChange={handleSelectedChange}
        checkedOnlyItem={true}
      />
    </Box>
  );
}
export { QuoteManagement };
