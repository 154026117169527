import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Table, SearchInput, SelectStatus } from 'components';
import { FormDetail } from './form-detail';
import { Box, Button, IconButton } from '@mui/material';
import { BsArrowDownCircle, BsArrowUpCircle, BsPlus, BsFilter } from 'react-icons/bs';
import Utils from 'commons/utils';
import ApiServices from 'services';
import { Columns } from './columns';

function ClientComplaints() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  const [totalRecords, setTotalRecords] = useState(0);

  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const searchInputRef = React.useRef(null);
  const reloadRef = React.useRef(null);
  useEffect(() => {
    if (loading) return;
    if (query) {
      clearTimeout(reloadRef.current);
      reloadRef.current = setTimeout(() => initData(), 100);
    }
  }, [query]);
  const onSearchInputChange = (value, key, searchArray = false) => {
    clearTimeout(searchInputRef.current);
    if (searchArray) {
      let newQuery = { ...query };
      if (!value) {
        delete newQuery.searchArray[key];
      } else {
        newQuery.searchArray = { ...newQuery.searchArray, [key]: value };
      }

      searchInputRef.current = setTimeout(() => setQuery(newQuery), 500);
    } else {
      searchInputRef.current = setTimeout(
        () => setQuery((prev) => ({ ...prev, [key]: value })),
        500,
      );
    }
  };

  const initData = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getPagingComplaints(query);
      if (totalRecords !== data?.totalRecords) setTotalRecords(data?.totalRecords);
      setData(data?.data || []);
    } catch (err) {
      console.warn('initData - Clients', err);
    }
    setLoading(false);
  };

  const [anchorElStatus, setAnchorElStatus] = React.useState(null);
  const isOpenMenuStatus = Boolean(anchorElStatus);
  const [dataRow, setDataRow] = React.useState({});

  const handleMenuClose = () => {
    setAnchorElStatus(null);
  };

  const handleMenuStatusOpen = (currentTarget, row) => {
    setDataRow(row);
    setAnchorElStatus(currentTarget);
  };

  const updateStatus = async (status) => {
    const valuesSend = { ...dataRow, status: status };
    handleMenuClose();
  };
  // ============================================================

  const handelSelectedRow = (row) => {
    const convertDataSelect = {
      id: row?.id,
      status: row?.status,
      createdBy: row?.createdBy,
      createdOn: row?.createdOn,
      updatedBy: row?.updatedBy,
      code: row?.code,
      clientCompanyId: row?.clientCompanyId,
      clientId: row?.clientId,
      problemlevel: row?.problemlevel,
      expirationDate: Utils.convertToDateFormat(row?.expirationDate),
      causeofComplaint: row?.causeofComplaint,
      description: row?.description,
      requestVerification: row?.verification?.requestVerification,
      rateVerification: row?.verification?.rateVerification,
      verifier: row?.verification?.verifier,
      verificationDate: Utils.convertToDateFormat(row?.verification?.verificationDate),
      responsibleEntityId: row?.verification?.responsibleEntityId,
      mainFollowers: row?.verification?.mainFollowers,
      proposer: row?.verification?.proposer,
      solution: row?.solution,
      approvedNote: row?.approved?.approvedNote,
      approvedby: row?.approved?.approvedby,
      approvedOn: Utils.convertToDateFormat(row?.approved?.approvedOn),
      contentsProcessed: row?.process?.contentsProcessed,
      handlerCode: row?.process?.handlerCode,
      processingDate: Utils.convertToDateFormat(row?.process?.processingDate),
      checkNote: row?.check?.checkNote,
      checker: row?.check?.checker,
      checkDate: Utils.convertToDateFormat(row?.check?.checkDate),
      arisingtype: row?.arising?.arisingtype,
      moneyArising: row?.arising?.moneyArising,
      isNew: false,
    };

    setSelected(convertDataSelect);
  };

  const handleRenderHeader = () => (
    <>
      <ContainerHeader>
        <SearchInput />
      </ContainerHeader>
      <IconButton aria-label="filter" sx={{ color: '#6C757D' }}>
        <BsFilter />
      </IconButton>
      <ButtonImport variant="outlined" startIcon={<BsArrowDownCircle />} size="small">
        Nhập excel
      </ButtonImport>
      <ButtonExport variant="outlined" startIcon={<BsArrowUpCircle />} size="small">
        Tải excel
      </ButtonExport>
      <ButtonAdd
        variant="contained"
        size="small"
        startIcon={<BsPlus />}
        onClick={() => setSelected({ isNew: true })}
      >
        Thêm mới
      </ButtonAdd>
    </>
  );

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Table
          rows={data}
          headCells={Columns(onSearchInputChange)}
          onClickDetail={(row) => handelSelectedRow(row)}
          title="Khách Hàng Khiếu Nại"
          loading={loading}
          renderHeader={handleRenderHeader}
        />
        <FormDetail {...{ selected, setSelected, initData }} />
      </Box>
      <SelectStatus
        idMenu="test"
        anchorElStatus={anchorElStatus}
        handleMenuClose={handleMenuClose}
        isOpenMenuStatus={isOpenMenuStatus}
        updateStatus={updateStatus}
      />
    </>
  );
}
export { ClientComplaints };

export const ContainerHeader = styled(Box)(({ theme }) => ({
  flex: 1,
}));

export const ButtonAdd = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  marginLeft: '5px',
  color: '#FFF',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '2px',
  },
}));

export const ButtonExport = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  marginLeft: '5px',
  color: '#6C757D',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '2px',
  },
}));

export const ButtonImport = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  marginLeft: '5px',
  color: '#6C757D',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  padding: '4px 15px',
  '.MuiButton-startIcon': {
    marginRight: '2px',
  },
}));
