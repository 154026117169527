import React from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput } from 'components';
import { Box, Button } from '@mui/material';
import { Formik } from 'formik';
import ApiServices from 'services';

const FORM_WIDTH = '500px';
export const FormDetail = ({ selected, setSelected, initData, ...props }) => {
  const createFn = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const { status } = await ApiServices.createClientStatus(values);
      if (status === 200) {
        window.UIMessage?.success('Thêm mới thành công');
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Thêm mới thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const updateFn = async (values, { setSubmitting }) => {
    delete values.updatedOn;
    setSubmitting(true);
    try {
      const { data } = await ApiServices.updateClientStatus(values.id, values);
      if (data) {
        window.UIMessage?.success('Cập nhật thành công');
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Cập nhật thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  return (
    <>
      <HandleFormDetail
        {...{ selected, setSelected, width: FORM_WIDTH }}
        showTitleTab={selected?.isNew ? 'Tạo mới' : `Trạng thái: ${selected?.statusName}`}
      >
        <Formik
          initialValues={{
            code: '',
            statusName: '',
            careTime: '',
            note: '',
            ...selected,
          }}
          validationSchema={Yup.object().shape({
            code: Yup.string().max(255).required('Vui lòng nhập mã nhóm!!!'),
            statusName: Yup.string().max(255).required('Vui lòng nhập trạng thái!!!'),
            careTime: Yup.number()
              .integer()
              .min(0, 'Thời gian chăm sóc phải lớn hơn hoặc bằng 0')
              .required('Vui lòng nhập thời gian chăm sóc!!!'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            if (selected?.isNew) {
              createFn(values, { setSubmitting });
            } else {
              updateFn(values, { setSubmitting });
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <TextInput
                required
                error={Boolean(touched.code && errors.code)}
                helperText={touched.code && errors.code}
                label="Mã"
                name="code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
              />
              <TextInput
                required
                error={Boolean(touched.statusName && errors.statusName)}
                helperText={touched.statusName && errors.statusName}
                label="Trạng thái"
                name="statusName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.statusName}
              />
              <TextInput
                required
                error={Boolean(touched.careTime && errors.careTime)}
                helperText={touched.careTime && errors.careTime}
                label="Thời gian chăm sóc"
                name="careTime"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.careTime}
                type="number"
              />
              <TextInput
                error={Boolean(touched.note && errors.note)}
                helperText={touched.note && errors.note}
                label="Ghi chú"
                name="note"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.note}
              />
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </HandleFormDetail>
    </>
  );
};
