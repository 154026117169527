import React from 'react';
import { Box, Button, Typography, Chip, IconButton, Menu, MenuItem } from '@mui/material';

export const SelectStatus = ({
  idMenu,
  anchorElStatus,
  handleMenuClose,
  isOpenMenuStatus,
  updateStatus,
}) => {
  return (
    <Menu
      anchorEl={anchorElStatus}
      id={idMenu}
      keepMounted
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 2,
            right: 80,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
          '.MuiMenu-paper': {
            background: '#FFFFFF',
            borderRadius: '10px',
            boxShadow: '0px 5px 15px rgba(7, 5, 122, 0.3)',
            transform: 'rotate(-0.46deg)',
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
      open={isOpenMenuStatus}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => updateStatus('active')}
        sx={{ color: '#007F00', backgroundColor: '#CDFFCD', padding: '0px 16px 0px 16px' }}
      >
        Active
      </MenuItem>
      <MenuItem
        onClick={() => updateStatus('deactive')}
        sx={{
          // color: 'rgb(183, 129, 3)',
          backgroundColor: '#FFE0E0',
          padding: '0px 16px 0px 16px',
        }}
      >
        Deactivate
      </MenuItem>
      <MenuItem
        onClick={() => updateStatus('draft')}
        sx={{
          color: 'rgb(183, 129, 3)',
          backgroundColor: 'rgba(255, 193, 7, 0.16)',
          padding: '0px 16px 0px 16px',
        }}
      >
        Draft
      </MenuItem>
    </Menu>
  );
};
