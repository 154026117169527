import React from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput, ArrayInput, Selection } from 'components';
import { Box, Button, Avatar, IconButton, Divider } from '@mui/material';
import { Formik } from 'formik';
import ApiServices from 'services';
import Storage from 'storage';
import { PHONE_REG_EXP } from 'commons/constants';
import Utils from 'commons/utils';
import { BtnSubmit } from './styles';

const FORM_WIDTH = '500px';

export const FormDetail = ({ selected, setSelected, initData, ...props }) => {
  const avatarRef = React.useRef(null);
  const [avatar, setAvatar] = React.useState(null);
  const isClientShare = !selected?.isNew && selected?.companyId !== Storage.user.CompanyId;
  React.useEffect(() => {
    if (selected) {
      setAvatar(selected?.urlImage);
    }
  }, [selected]);

  const createFn = async (values, { setSubmitting }) => {
    const dataSend = values;

    dataSend.companyId = Storage?.user?.CompanyId;
    if (values.foundingDate === '') {
      delete dataSend.foundingDate;
    }
    if (values.birthdayRepresenter === '') {
      delete dataSend.birthdayRepresenter;
    }

    setSubmitting(true);
    try {
      const { status } = await ApiServices.createClientCompany(dataSend);
      if (status === 200) {
        window.UIMessage?.success('Thêm mới thành công');
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Thêm mới thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const updateFn = async (values, { setSubmitting }) => {
    delete values.updatedOn;
    if (values.birthdayRepresenter === '') {
      delete values.birthdayRepresenter;
    }
    if (values.foundingDate === '') {
      delete values.foundingDate;
    }

    setSubmitting(true);
    try {
      const { data } = await ApiServices.updateClientCompany(values.id, values);
      if (data) {
        window.UIMessage?.success('Cập nhật thành công');
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Cập nhật thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const onChangeAvatarFn = async (event) => {
    try {
      const { data } = await ApiServices.changeAvatarClientCompany(selected?.id, {
        file: event.target.files[0],
      });
      if (data) {
        setAvatar(data?.urlImage);
        initData();
        window.UIMessage?.success('Cập nhật thành công');
      }
    } catch (err) {
      console.warn('import excel - Change Avatar', err);
      window.UIMessage?.error('Cập nhật thất bại !!!');
    }
  };
  const renderInformationClient = (values) => {
    const INFOR_CLIENT = [
      {
        title: 'Mã',
        value: values?.code,
      },
      {
        title: 'Tên khách hàng',
        value: values?.name,
      },
      {
        title: 'Mã số thuế',
        value: values?.tax_Code,
      },
      {
        title: 'Địa chỉ',
        value: values?.arrAddress,
      },
      {
        title: 'Fax',
        value: values?.arrFax,
      },
      {
        title: 'Số điện thoại',
        value: values?.arrNumberPhone,
      },
      {
        title: 'Email',
        value: values?.email,
      },
      {
        title: 'Thành lập',
        value: Utils.formatDateFn(values?.foundingDate),
      },
      {
        title: 'Loại công ty',
        value: values?.companyType,
      },
      {
        title: 'Loại hàng hóa',
        value: values?.cargotype,
      },
      {
        title: 'Người đại diện',
        value: values?.representer,
      },
      {
        title: 'Ngày sinh người đại diện',
        value: Utils.formatDateFn(values?.birthdayRepresenter),
      },
      {
        title: 'SĐT người đại diện',
        value: values?.phoneRepresenter,
      },
      {
        title: 'Email người đại diện',
        value: values?.emailRepresenter,
      },
      {
        title: 'Ghi chú',
        value: values?.note,
      },
    ];

    return INFOR_CLIENT.map((item, index) => {
      return (
        <div key={`${item?.title} - ${index}`}>
          <b>{item?.title}: </b> <span style={{ color: 'blue' }}>{item?.value}</span>
        </div>
      );
    });
  };

  return (
    <>
      <HandleFormDetail
        {...{ selected, setSelected, width: FORM_WIDTH }}
        showTitleTab={selected?.isNew ? 'Tạo mới' : `Mã: ${selected?.code}`}
        showTitleTabRight={
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            sx={{
              padding: 0,
              cursor: 'pointer',
            }}
            disabled={selected?.companyId !== Storage.user.CompanyId}
            onClick={() => {
              avatarRef.current.click();
            }}
          >
            <Avatar
              alt={selected?.code}
              sx={{
                width: 80,
                height: 80,
                objectFit: 'cover',
              }}
              src={`${process.env.REACT_APP_API_URL}/${avatar}`}
            />
            <input
              accept="image/*"
              ref={avatarRef}
              type="file"
              hidden
              onChange={onChangeAvatarFn}
            />
          </IconButton>
        }
      >
        <Formik
          initialValues={{
            code: '',
            name: '',
            tax_Code: '',
            arrAddress: [],
            arrNumberPhone: [],
            arrFax: [],
            email: '',
            companyType: '',
            arrServiceId: [],
            loyaltyTypeId: '',
            foundingDate: '',
            note: '',
            cargotype: '',
            representer: '',
            birthdayRepresenter: '',
            emailRepresenter: '',
            phoneRepresenter: '',
            ...selected,
          }}
          validationSchema={Yup.object().shape(
            {
              code: Yup.string()
                .max(15, 'Mã chỉ được nhập tối đa 15 ký tự')
                .required('Vui lòng nhập mã nhóm!!!'),
              name: Yup.string().max(255).required('Vui lòng nhập tên khách hàng!!!'),
              tax_Code: Yup.string().max(255).required('Vui lòng nhập mã số thuế'),
              arrServiceId: Yup.array().min(1, 'Vui lòng chọn dịch vụ!!!').nullable(),
              email: Yup.string()
                .email('Email không hợp lệ')
                .when('arrNumberPhone', {
                  is: (val) => (val?.length ? false : true),
                  then: (schema) => schema.required('Vui lòng nhập email'),
                }),
              arrNumberPhone: Yup.array()
                .when('email', {
                  is: (val) => (val?.length ? false : true),
                  then: (schema) =>
                    schema
                      .required('Vui lòng nhập số điện thoại')
                      .min(1, 'Vui lòng nhập ít nhất 1 số điện thoại'),
                })
                .of(
                  Yup.string()

                    .matches(
                      PHONE_REG_EXP,
                      ({ value }) => 'Số điện thoại "' + value + '" không hợp lệ',
                    )
                    .min(1, 'Số điện thoại không hợp lệ')
                    .max(14, 'Số điện thoại có 14 ký tự'),
                ),
            },
            ['email', 'arrNumberPhone'],
          )}
          onSubmit={(values, { setSubmitting }) => {
            if (selected?.isNew) {
              createFn(values, { setSubmitting });
            } else {
              updateFn(values, { setSubmitting });
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              {isClientShare ? (
                <>
                  <Box sx={{ m: 2 }}>{renderInformationClient(values)}</Box>
                  <Divider>Danh sách dịch vụ</Divider>
                  <Selection
                    required
                    error={Boolean(touched.arrServiceId && errors.arrServiceId)}
                    helperText={touched.arrServiceId && errors.arrServiceId}
                    label="Danh sách dịch vụ"
                    name="arrServiceId"
                    multiple={true}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.arrServiceId}
                    syncData={ApiServices.getServices}
                    listDisable={values?.arrServiceReceivedId}
                  />
                </>
              ) : (
                <>
                  <TextInput
                    required
                    error={Boolean(touched.code && errors.code)}
                    helperText={touched.code && errors.code}
                    label="Mã"
                    name="code"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.code}
                  />
                  <TextInput
                    required
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    label="Khách hàng"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                  />
                  <TextInput
                    required
                    error={Boolean(touched.tax_Code && errors.tax_Code)}
                    helperText={touched.tax_Code && errors.tax_Code}
                    label="Mã số thuế"
                    name="tax_Code"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.tax_Code}
                  />
                  <ArrayInput
                    value={values.arrAddress}
                    fullWidth
                    onChange={handleChange}
                    label="Địa chỉ"
                    name="arrAddress"
                  />
                  <ArrayInput
                    value={values.arrNumberPhone}
                    helperText={touched.arrNumberPhone && errors.arrNumberPhone}
                    error={Boolean(touched.arrNumberPhone && errors.arrNumberPhone)}
                    fullWidth
                    onChange={handleChange}
                    label="SĐT"
                    name="arrNumberPhone"
                  />
                  <ArrayInput
                    value={values.arrFax}
                    fullWidth
                    onChange={handleChange}
                    label="Fax"
                    name="arrFax"
                  />
                  <TextInput
                    required
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    label="Email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                  />
                  <TextInput
                    error={Boolean(touched.foundingDate && errors.foundingDate)}
                    helperText={touched.foundingDate && errors.foundingDate}
                    label="Thành Lập"
                    name="foundingDate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.foundingDate || ''}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextInput
                    error={Boolean(touched.companyType && errors.companyType)}
                    helperText={touched.companyType && errors.companyType}
                    label="Loại công ty"
                    name="companyType"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.companyType}
                  />
                  <Selection
                    required
                    error={Boolean(touched.arrServiceId && errors.arrServiceId)}
                    helperText={touched.arrServiceId && errors.arrServiceId}
                    label="Danh sách dịch vụ"
                    name="arrServiceId"
                    multiple={true}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.arrServiceId}
                    syncData={ApiServices.getServices}
                    listDisable={values?.arrServiceReceivedId}
                  />
                  <TextInput
                    error={Boolean(touched.cargotype && errors.cargotype)}
                    helperText={touched.cargotype && errors.cargotype}
                    label="Loại hàng hóa"
                    name="cargotype"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.cargotype}
                  />
                  <TextInput
                    error={Boolean(touched.representer && errors.representer)}
                    helperText={touched.representer && errors.representer}
                    label="Người đại diện"
                    name="representer"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.representer}
                  />
                  <TextInput
                    error={Boolean(touched.birthdayRepresenter && errors.birthdayRepresenter)}
                    helperText={touched.birthdayRepresenter && errors.birthdayRepresenter}
                    label="Ngày sinh người đại diện"
                    name="birthdayRepresenter"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.birthdayRepresenter || ''}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextInput
                    error={Boolean(touched.emailRepresenter && errors.emailRepresenter)}
                    helperText={touched.emailRepresenter && errors.emailRepresenter}
                    label="Email người đại diện"
                    name="emailRepresenter"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.emailRepresenter}
                  />
                  <TextInput
                    error={Boolean(touched.phoneRepresenter && errors.phoneRepresenter)}
                    helperText={touched.phoneRepresenter && errors.phoneRepresenter}
                    label="SĐT người đại diện"
                    name="phoneRepresenter"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phoneRepresenter}
                  />
                  <TextInput
                    error={Boolean(touched.note && errors.note)}
                    helperText={touched.note && errors.note}
                    label="Ghi chú"
                    name="note"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.note}
                  />
                </>
              )}

              <Box sx={{ py: 2 }}>
                <BtnSubmit
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  {isClientShare ? 'Cập nhật dịch vụ' : selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                </BtnSubmit>
              </Box>
            </form>
          )}
        </Formik>
      </HandleFormDetail>
    </>
  );
};
