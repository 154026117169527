import React, { useState, useEffect } from 'react';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import ApiServices from 'services';
import { QuoteCustomsSubChargeForm } from '../../forms-management/quote-customs-sub-charge-form';
import { QuoteTruckingSubChargeForm } from '../../forms-management/quote-trucking-sub-charge-form';
import { SERVICES_ID } from 'commons/constants';
import { LoadingInContainer } from 'components';

const SubCharge = ({ dataSubCharge }) => {
  const { CUSTOMS, TRUCKING } = SERVICES_ID;
  const [loading, setLoading] = useState(false);
  const [subChargeList, setSubChargeList] = useState([]);

  const getSurchargeByFeeGroupSS = async (id) => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getSurchargeByFeeGroupSS(id, true);
      if (data) {
        const dataMapFeeGroup = data.map((item) => {
          const ind = dataSubCharge?.arrSurcharge?.findIndex(
            (i) => i.feeGroupID === item.feeGroupID,
          );
          if (ind !== -1) {
            item.surcharge = dataSubCharge?.arrSurcharge[ind]?.surcharge || [];
            item.checked = true;
          } else {
            item.checked = false;
          }

          return item;
        });
        setSubChargeList(dataMapFeeGroup || []);
      }
    } catch (error) {
      console.warn('getSurchargeByFeeGroupSS:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (dataSubCharge?.subServiceId) {
      getSurchargeByFeeGroupSS(dataSubCharge?.subServiceId);
    }
  }, []);

  const renderSubChargeCustoms = (item, index) => (
    <FormGroup key={item?.feeGroupID + index}>
      <FormControlLabel
        sx={{ width: '30%' }}
        control={<Checkbox checked={item?.checked} />}
        label={item?.feeGroupName}
      />
      {item?.checked && (
        <QuoteCustomsSubChargeForm
          arrSurcharge={item?.surchargeListFree}
          name={item?.feeGroupID}
          value={item?.surcharge}
          showQuantity={false}
        />
      )}
    </FormGroup>
  );

  const renderSubChargeTrucking = (item, index) => (
    <FormGroup key={item?.feeGroupID + index}>
      <FormControlLabel
        sx={{ width: '30%' }}
        control={<Checkbox checked={item?.checked} />}
        label={item?.feeGroupName}
      />
      {item?.checked && (
        <QuoteTruckingSubChargeForm
          arrSurcharge={item?.surchargeListFree}
          name={item?.feeGroupID}
          value={item?.surcharge}
        />
      )}
    </FormGroup>
  );
  if (loading) return <LoadingInContainer />;
  if (dataSubCharge?.serviceId === CUSTOMS) {
    return subChargeList?.map(renderSubChargeCustoms);
  } else if (dataSubCharge?.serviceId === TRUCKING) {
    return subChargeList?.map(renderSubChargeTrucking);
  }
};

export { SubCharge };
