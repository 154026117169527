import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Table } from 'components';
import { FormDetail } from './form-detail';
import { Box, IconButton, Chip, Typography } from '@mui/material';
import { BsArrowDownCircle, BsFilter } from 'react-icons/bs';
import ApiServices from 'services';
import { getColumns } from './columns';
import { ContainerHeader, ButtonExport } from './styles';
import { CLIENT_STATUS_ID } from 'commons/constants';
import Utils from 'commons/utils';

function ClientsHolding() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  const initData = async () => {
    setLoading(true);
    await ApiServices.getClientCareHistoryByStatus(CLIENT_STATUS_ID.LEAD_HOLDING)
      .then((res) => {
        if (res?.data) {
          setData(res?.data || []);
        }
      })
      .catch((err) => console.warn('initData - Client Sources', err));
    setLoading(false);
  };
  useEffect(() => {
    initData();
  }, []);

  const deleteCell = async (row) => {
    setLoading(true);
    await ApiServices.updateClientStatus(row?.id, CLIENT_STATUS_ID.LEAD_TAKING_CARE)
      .then((res) => {
        if (res?.data) {
          window.UIMessage?.success('Cập nhật thành công');
          initData();
        }
      })
      .catch((err) => console.log('Delete Item holding', err));
    setLoading(false);
  };

  function typeClient(type) {
    switch (type) {
      case 'Kim Cương':
        return <Chip label="Kim Cương" sx={{ color: 'black', backgroundColor: '#00BFFF' }} />;
      case 'Bạch Kim':
        return <Chip label="Bạch Kim" sx={{ color: 'black', backgroundColor: '#66CDAA' }} />;
      case 'Bạc':
        return <Chip label="Bạc" sx={{ color: 'black', backgroundColor: '#C0C0C0' }} />;
      case 'Vàng':
        return <Chip label="Vàng" sx={{ color: 'black', backgroundColor: '#FFD700' }} />;

      default:
        return <Chip label="Mới" />;
    }
  }

  const handleExportClientHolding = async () => {
    setLoading(true);
    await ApiServices.exportExcel_TamNgung()
      .then((res) => {
        if (res?.data) {
          Utils.exportTemplate(
            res?.data,
            `DS_Khach_Hang_Tam_Ngung_${Utils.formatSimpleDate(moment())}.xlsx`,
          );
        }
      })
      .catch((err) => {
        console.log('Export Client Company error:', err);
        window.UIMessage?.error('Tải thất bại !!!');
      });
    setLoading(false);
  };
  const renderHeader = () => (
    <>
      <ContainerHeader>
        <Typography variant="h6" component="h6" sx={{ m: '10px' }}>
          Danh Sách Khách Hàng Tạm Ngưng
        </Typography>
      </ContainerHeader>

      <IconButton aria-label="filter" sx={{ color: '#6C757D' }}>
        <BsFilter />
      </IconButton>
      <ButtonExport
        variant="outlined"
        startIcon={<BsArrowDownCircle />}
        onClick={handleExportClientHolding}
      >
        Tải excel
      </ButtonExport>
    </>
  );

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Table
          rows={data}
          headCells={getColumns(deleteCell, typeClient)}
          loading={loading}
          renderHeader={renderHeader}
        />
        <FormDetail {...{ selected, setSelected, initData }} />
      </Box>
    </>
  );
}
export { ClientsHolding };
