import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Box } from '@mui/material';
import { NumberFormatCustom } from 'components';

const InputItem = React.forwardRef(
  ({ item, index, setValues, keyArray, keyNameOfArray, cargoTypeList, disabled }, ref) => {
    const [arrayCont, setArrayCont] = React.useState([]);

    React.useImperativeHandle(ref, () => ({
      getAlert() {
        alert('getAlert from Child');
      },
    }));
    React.useEffect(() => {
      setArrayCont(item[keyArray] || []);
    }, [item]);

    const renderLabel = (id) => {
      const ind = cargoTypeList?.findIndex((item) => item.id === id);
      if (ind === -1) return '';
      return cargoTypeList[ind]?.name;
    };

    return (
      <Container>
        <Box sx={{ width: '200px', marginRight: '10px' }}>
          <StyledTextCont
            label="Loại cont"
            variant="outlined"
            type="text"
            size="small"
            value={item?.contTypeCode}
          />
        </Box>
        {arrayCont?.map((el, indContType) => (
          <Box sx={{ width: '160px', marginRight: '10px' }} key={`cargoTypePrice-${indContType}`}>
            <TextFieldCargoTypePrice
              label={renderLabel(el[keyNameOfArray])}
              variant="outlined"
              type="text"
              size="small"
              InputProps={{
                inputComponent: NumberFormatCustom,
                disabled: true,
              }}
              value={el?.cargoTypePrice}
            />
          </Box>
        ))}
        {arrayCont?.map((el, indContType) => (
          <Box sx={{ width: '200px', marginRight: '10px' }} key={`MakeUp-${indContType}`}>
            <TextFieldMakeUp
              label={`%MakeUp ${renderLabel(el[keyNameOfArray])}`}
              name="makeup"
              variant="outlined"
              type="text"
              size="small"
              InputProps={{
                inputComponent: NumberFormatCustom,
                disabled: true,
              }}
              value={el?.makeup}
            />
          </Box>
        ))}
        {arrayCont?.map((el, indContType) => (
          <Box sx={{ width: '200px', marginRight: '10px' }} key={`Price-${indContType}`}>
            <TextFieldBuyPrice
              label={`Giá bán ${renderLabel(el[keyNameOfArray])}`}
              variant="outlined"
              type="text"
              size="small"
              InputProps={{
                inputComponent: NumberFormatCustom,
                disabled: true,
              }}
              value={el?.buyPrice}
            />
          </Box>
        ))}
      </Container>
    );
  },
);
export default InputItem;

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '10px',
}));
const StyledTextCont = styled(TextField)(({ theme }) => ({
  marginRight: '10px',
  width: '200px',
}));
const TextFieldCargoTypePrice = styled(TextField)(({ theme }) => ({
  width: '160px',
}));
const TextFieldMakeUp = styled(TextField)(({ theme }) => ({
  width: '200px',
  backgroundColor: '#FCF2EF',
}));
const TextFieldBuyPrice = styled(TextField)(({ theme }) => ({
  width: '200px',
  backgroundColor: 'rgba(6, 95, 212, 0.1)',
}));
