import React from 'react';
import Utils from 'commons/utils';
import { SearchInputColumn } from 'components';
import { Button, Badge } from '@mui/material';

export const getColumns = (onChangeSearch, onHandleAccept, onHandleReject) => [
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: <SearchInputColumn title="Mã" name="code" onChange={onChangeSearch} />,
    width: '10px',
    align: 'left',
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    align: 'left',
    width: '200px',
    label: <SearchInputColumn title="Tên công ty" name="name" onChange={onChangeSearch} />,
  },
  {
    id: 'arrNumberPhone',
    numeric: true,
    disablePadding: false,
    label: <SearchInputColumn title="SĐT" name="arrNumberPhone" onChange={onChangeSearch} />,
    align: 'left',
    render: (row) => Utils.showArrayInTale(row.arrNumberPhone),
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: <SearchInputColumn title="Email" name="email" onChange={onChangeSearch} />,
    align: 'left',
  },
  {
    id: 'arrAddress',
    numeric: true,
    disablePadding: false,
    label: <SearchInputColumn title="Địa chỉ" name="arrAddress" onChange={onChangeSearch} />,
    width: '250px',
    align: 'left',
    render: (row) => Utils.showArrayInTale(row.arrAddress),
  },
  {
    id: 'tax_Code',
    numeric: true,
    disablePadding: false,
    label: <SearchInputColumn title="Mã số thuế" name="tax_Code" onChange={onChangeSearch} />,
    align: 'left',
  },
  {
    id: 'arrFax',
    numeric: true,
    disablePadding: false,
    label: <SearchInputColumn title="Fax" name="arrFax" onChange={onChangeSearch} />,
    align: 'left',
    render: (row) => Utils.showArrayInTale(row.arrFax),
  },
  {
    id: 'companyType',
    numeric: true,
    disablePadding: false,
    label: <SearchInputColumn title="Loại CT" name="companyType" onChange={onChangeSearch} />,
    align: 'left',
  },
  {
    id: 'note',
    numeric: true,
    disablePadding: false,
    label: <SearchInputColumn title="Ghi chú" name="note" onChange={onChangeSearch} />,
    align: 'left',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Thao tác',
    width: '200px',
    fixed: 'right',
    render: (row) => (
      <>
        <Button
          variant="contained"
          size="small"
          color="success"
          sx={{ borderRadius: '15px', textTransform: 'none', m: '5px' }}
          onClick={() => onHandleAccept(row)}
        >
          Xác nhận
        </Button>
        <Badge badgeContent={row.reject || 0} color="primary">
          <Button
            variant="contained"
            size="small"
            color="error"
            sx={{ borderRadius: '15px', textTransform: 'none' }}
            onClick={() => onHandleReject(row)}
          >
            Từ chối
          </Button>
        </Badge>
      </>
    ),
  },
];
