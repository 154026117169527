import React, { useState, useEffect } from 'react';
import { SUB_SERVICES_ID } from 'commons/constants';
import { LoadingInContainer } from 'components';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { QuoteCustomsCargoTypeForm } from '../../forms-management/quote-customs-cargo-type-form';
import { QuoteCustomsWeightForm } from '../../forms-management/quote-customs-weight-form';
import { QuoteTruckingPriceForm } from '../../forms-management/quote-trucking-price-form';
import { QuoteTruckingReturnContForm } from '../../forms-management/quote-trucking-return-cont-form';
import { SubCharge } from './sub-charge';
import ApiServices from 'services';

import { HeaderFreight } from './header';
import { ContainerPrice } from './styles';

const MergeCustomsAndTruckingForm = ({ dataTrucking, dataCustoms }) => {
  const { CUSTOMS_FCL } = SUB_SERVICES_ID;

  const [loading, setLoading] = useState(false);
  const [cargoTypeList, setCargoTypeList] = useState([]);
  const [locationVTList, setLocationVTList] = useState([]);

  const getCargoType = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getCargoTypes();
      if (data) {
        setCargoTypeList(data || []);
      }
    } catch (err) {
      console.warn('getCargoType', err);
    } finally {
      setLoading(false);
    }
  };
  const getLocationVT = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getLocationVTs();
      if (data) {
        setLocationVTList(data || []);
      }
    } catch (err) {
      console.warn('getLocationVT', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCargoType();
    getLocationVT();
  }, []);

  const renderCustomsService = (item) => (
    <>
      <HeaderFreight item={item} />
      <b>Giá cước:</b>
      <ContainerPrice>
        {item?.subServiceId === CUSTOMS_FCL && (
          <QuoteCustomsCargoTypeForm
            arrContType={item?.arrTypeCargoIdCheck}
            cargoTypeList={cargoTypeList}
            name="arrCont"
            requiredTitle="Vui lòng nhập đầy đủ thông tin"
            value={item?.arrCont}
          />
        )}
        {item?.subServiceId !== CUSTOMS_FCL && (
          <QuoteCustomsWeightForm
            name="arrSpecificationsWeight"
            requiredTitle="Vui lòng nhập đầy đủ thông tin"
            value={item?.arrSpecificationsWeight}
          />
        )}
        <SubCharge dataSubCharge={item} />
      </ContainerPrice>
    </>
  );

  const renderTruckingService = (item) => (
    <>
      <HeaderFreight item={item} />
      <b>Giá cước:</b>
      <ContainerPrice>
        <QuoteTruckingPriceForm
          arrContType={item?.arrTypeCargoCode}
          cargoTypeList={cargoTypeList}
          name="arrContTractor"
          requiredTitle="Vui lòng nhập đầy đủ thông tin"
          value={item?.arrContTractor}
        />
        <FormGroup>
          <FormControlLabel
            sx={{ width: '50%' }}
            control={<Checkbox checked={item?.subChargeNewPort?.length > 0 ? true : false} />}
            label="Lấy hoặc trả cont ở cảng khác"
          />
        </FormGroup>
        {item?.subChargeNewPort?.length > 0 && (
          <QuoteTruckingReturnContForm
            locationVTList={locationVTList}
            name="subChargeNewPort"
            requiredTitle="Vui lòng nhập đầy đủ thông tin"
            value={item.subChargeNewPort}
          />
        )}
        <SubCharge dataSubCharge={item} />
      </ContainerPrice>
    </>
  );

  if (loading) return <LoadingInContainer />;
  return (
    <>
      {dataTrucking && renderTruckingService(dataTrucking)}
      {dataCustoms && renderCustomsService(dataCustoms)}
    </>
  );
};

export { MergeCustomsAndTruckingForm };
