import React, { useState, useEffect, useMemo } from 'react';
import { LoadingInContainer } from 'components';
import { SUB_SERVICES_ID, QUOTE_STATUS } from 'commons/constants';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { QuoteCustomsCargoTypeForm } from '../../forms-management/quote-customs-cargo-type-form';
import { QuoteCustomsSubChargeForm } from '../../forms-management/quote-customs-sub-charge-form';
import { QuoteCustomsWeightForm } from '../../forms-management/quote-customs-weight-form';
import ApiServices from 'services';

import { HeaderFreight } from './header';
import { ContainerPrice } from './styles';

const CustomsControlQuoteForm = ({ dataCustoms }) => {
  const { CUSTOMS_FCL, CUSTOMS_LCL, CUSTOMS_AIR } = SUB_SERVICES_ID;
  const [groupFeeGroup, setGroupFeeGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cargoTypeList, setCargoTypeList] = useState([]);

  const isCustomsFcl = useMemo(() => {
    return dataCustoms?.subServiceId === CUSTOMS_FCL;
  }, [dataCustoms?.subServiceId]);
  const isCustomsLcl = useMemo(() => {
    return dataCustoms?.subServiceId === CUSTOMS_LCL;
  }, [dataCustoms?.subServiceId]);
  const isCustomsAir = useMemo(() => {
    return dataCustoms?.subServiceId === CUSTOMS_AIR;
  }, [dataCustoms?.subServiceId]);

  const isDisabledForm = useMemo(() => {
    if (dataCustoms?.isNew) return false;
    if (dataCustoms?.quotesStatus === QUOTE_STATUS.ORIGIN_QUOTE) return false;
    return true;
  }, [dataCustoms]);

  const getCargoType = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getCargoTypes();
      if (data) {
        setCargoTypeList(data || []);
      }
    } catch (err) {
      console.warn('getCargoType', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCargoType();
  }, []);

  const getSurchargeByFeeGroupSS = async (id) => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getSurchargeByFeeGroupSS(id, true);
      if (data) {
        const dataMapFeeGroup = data.map((item) => {
          const ind = dataCustoms?.arrSurcharge?.findIndex((i) => i.feeGroupID === item.feeGroupID);
          if (ind !== -1) {
            item.surcharge = dataCustoms?.arrSurcharge[ind]?.surcharge || [];
            item.checked = true;
          } else {
            item.checked = false;
          }

          return item;
        });
        setGroupFeeGroup(dataMapFeeGroup || []);
      }
    } catch (error) {
      console.warn('getSurchargeByFeeGroupSS:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (dataCustoms?.subServiceId) {
      getSurchargeByFeeGroupSS(dataCustoms?.subServiceId);
    }
  }, []);

  const renderSubChargeCustoms = (item, index) => (
    <FormGroup key={item.feeGroupID + index}>
      <FormControlLabel
        sx={{ width: '30%' }}
        control={<Checkbox checked={item.checked} />}
        label={item.feeGroupName}
      />
      {item.checked && (
        <QuoteCustomsSubChargeForm
          arrSurcharge={item.surchargeListFree}
          name={item.feeGroupID}
          value={item?.surcharge}
          requiredTitle="Vui lòng nhập đầy đủ thông tin"
          showQuantity={false}
          disabled={isDisabledForm}
        />
      )}
    </FormGroup>
  );
  if (loading) return <LoadingInContainer />;
  return (
    <>
      <HeaderFreight dataCustoms={dataCustoms} />
      <b>Giá cước:</b>

      <ContainerPrice>
        {isCustomsFcl && (
          <QuoteCustomsCargoTypeForm
            arrContType={dataCustoms?.arrTypeCargoIdCheck}
            cargoTypeList={cargoTypeList}
            name="arrCont"
            requiredTitle="Vui lòng nhập đầy đủ thông tin"
            value={dataCustoms?.arrCont}
            disabled={isDisabledForm}
          />
        )}
        {(isCustomsLcl || isCustomsAir) && (
          <QuoteCustomsWeightForm
            name="arrSpecificationsWeight"
            requiredTitle="Vui lòng nhập đầy đủ thông tin"
            value={dataCustoms?.arrSpecificationsWeight}
            disabled={isDisabledForm}
          />
        )}
        {groupFeeGroup?.map(renderSubChargeCustoms)}
      </ContainerPrice>
    </>
  );
};

export { CustomsControlQuoteForm };
