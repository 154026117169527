import * as React from 'react';
import { TextField, MenuItem, Checkbox, ListItemText, ListItemIcon } from '@mui/material';
import RenderIcon from 'commons/render-icons';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export const Selection = ({
  value,
  name,
  onBlur,
  onChange,
  error,
  helperText,
  label = '',
  type = 'text',
  size = 'small',
  margin = 'dense',
  fullWidth = true,
  variant = 'outlined',
  maxRows = 4,
  syncData = null,
  syncDataOnlyOnce = null,
  fieldView = '',
  fieldValue = null,
  fieldShowFunc = null,
  disabledFunc = null,
  getValue = null,
  multiple = false,
  data = [],
  dataArrObject = [],
  dataArrObjectIcon = [],
  renderValue = null,
  checkBox = false,
  checkedRender = null,
  required,
  disabled = false,
  disabledItemNone = true,
  listDisable = [],
  sx = {},
  sxInput = {},
  ...props
}) => {
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [tempValue, setTempValue] = React.useState([]);
  const [loadingSyncData, setLoadingSyncData] = React.useState(false);

  const initData = async () => {
    if (!syncData) {
      setLoading(false);
      return;
    }
    setLoading(true);
    setLoadingSyncData(true);
    try {
      const { data } = await syncData();
      if (data.data) {
        setItems(data?.data || []);
      } else {
        setItems(data || []);
      }
    } catch (err) {
      console.log('ERROR - Selection initData', err);
    } finally {
      setLoadingSyncData(false);
      setLoading(false);
    }
  };
  React.useEffect(() => {
    multiple && setTempValue(value);
  }, [value]);
  React.useEffect(() => {
    if (!loadingSyncData) {
      initData();
    }
  }, [syncData]);

  const getDataOnlyOnce = async () => {
    if (!syncDataOnlyOnce) {
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const { data } = await syncDataOnlyOnce();
      if (data.data) {
        setItems(data?.data || []);
      } else {
        setItems(data || []);
      }
    } catch (err) {
      console.log('ERROR - Selection initData', err);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    if (syncDataOnlyOnce) {
      getDataOnlyOnce();
    }
  }, []);

  const renderCheckbox = (value, item) => <Checkbox checked={checkedRender(value, item)} />;
  const renderItem = (item) => (
    <>
      {checkBox && renderCheckbox(value, item)}
      <ListItemText
        sx={{ ...sxInput }}
        primary={
          fieldShowFunc ? fieldShowFunc(item) : fieldView ? item[fieldView] : item.name || item.code
        }
      />
    </>
  );

  return (
    <TextField
      select
      // value={loading ? '' : multiple ? tempValue : value}
      value={
        loading ? (multiple ? [] : '') : multiple ? tempValue : value || value === 0 ? value : ''
      }
      {...{
        name,
        error,
        helperText,
        label: required ? label + ' (*)' : label,
        fullWidth,
        type,
        size,
        variant,
        margin,
        sx,
        disabled,
      }}
      SelectProps={{
        multiple,
        renderValue,
        MenuProps,
        onChange: (e) => {
          if (multiple) {
            const {
              target: { value },
            } = e;
            setTempValue(
              // On autofill we get a stringified value.
              typeof value === 'string' ? value.split(',') : value,
            );
            // setTempValue(e.target.value);
          }
          onChange && onChange(e);
        },
      }}
    >
      <MenuItem value="" disabled={disabledItemNone}>
        <em>None</em>
      </MenuItem>
      {data?.map((item, index) => (
        <MenuItem key={`selection-key-${index}`} value={item}>
          {item}
        </MenuItem>
      ))}
      {dataArrObject?.map((item, index) => (
        <MenuItem key={`selection-key-${index}`} value={fieldValue ? item[fieldValue] : item.value}>
          {fieldView ? item[fieldView] : item.content}
        </MenuItem>
      ))}
      {dataArrObjectIcon?.map((item, index) => (
        <MenuItem key={`selection-key-${index}`} value={item.value}>
          <ListItemIcon key={index}>{RenderIcon.renderIcons(item.name)}</ListItemIcon>
          {item.name}
        </MenuItem>
      ))}
      {listDisable
        ? items?.map((item, index) => {
            const isDisabled = listDisable && listDisable.includes(item.id);
            return (
              <MenuItem
                key={`selection-key-${item?.id}-${index}`}
                disabled={isDisabled}
                value={getValue ? getValue(item) : fieldValue ? item[fieldValue] : item.id}
              >
                {multiple
                  ? fieldShowFunc
                    ? fieldShowFunc(item)
                    : fieldView
                    ? item[fieldView]
                    : item.name || item.code
                  : renderItem(item)}
              </MenuItem>
            );
          })
        : items?.map((item, index) => (
            <MenuItem
              key={`selection-key-${item?.id}-${index}`}
              disabled={disabledFunc && disabledFunc(item)}
              value={getValue ? getValue(item) : fieldValue ? item[fieldValue] : item.id}
            >
              {multiple
                ? fieldShowFunc
                  ? fieldShowFunc(item)
                  : fieldView
                  ? item[fieldView]
                  : item.name || item.code
                : renderItem(item)}
            </MenuItem>
          ))}
    </TextField>
  );
};
