import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, Grid, Chip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateContractData } from 'store/reducers/contract/action';
import { Selection, TextInput, SimpleComboBox } from 'components';
import { ContainerHeaderFormDetail } from './styles';
import ApiServices from 'services';
import Utils from 'commons/utils';
import { CONTRACT_STATUS_OPTIONS } from 'commons/constants';

const InformationGeneral = ({ selected }) => {
  const dispatch = useDispatch();
  const [clientCompanyId, setClientCompanyId] = useState('');
  const [contractNumber, setContractNumber] = useState('');
  const [referenceNumber, setReferenceNumber] = useState('');
  const [typeContractId, setTypeContractId] = useState('');
  const [effectiveDate, setEffectiveDate] = useState('');
  const [extensionDate, setExtensionDate] = useState('');
  const [expireDate, setExpireDate] = useState('');
  const [signedDate, setSignedDate] = useState('');
  const [paymentTermDate, setPaymentTermDate] = useState('');
  const [note, setNote] = useState('');
  const [arrUserCodeShare, setArrUserCodeShare] = useState([]);
  const [summaryNote, setSummaryNote] = useState('');

  useEffect(() => {
    if (selected && !selected?.isNew) {
      setClientCompanyId(selected?.clientCompanyId || '');
      setContractNumber(selected?.contractNumber || '');
      setReferenceNumber(selected?.referenceNumber || '');
      setTypeContractId(selected?.typeContractId || '');
      setEffectiveDate(selected?.effectiveDate || '');
      setExtensionDate(selected?.extensionDate || '');
      setExpireDate(selected?.expireDate || '');
      setSignedDate(selected?.signedDate || '');
      setPaymentTermDate(selected?.paymentTermDate || '');
      setNote(selected?.note || '');
      setArrUserCodeShare(selected?.arrUserCodeShare || []);
      setSummaryNote(selected?.summaryNote || '');
    }
  }, [selected]);

  const handleChangeClientCompany = useCallback(
    (e) => {
      setClientCompanyId(e.target.value);
      dispatch(updateContractData({ clientCompanyId: e.target.value }));
    },
    [clientCompanyId],
  );
  const handleChangeContractNumber = useCallback(
    (e) => {
      setContractNumber(e.target.value);
      dispatch(updateContractData({ contractNumber: e.target.value }));
    },
    [contractNumber],
  );
  const handleChangeReferenceNumber = useCallback(
    (e) => {
      setReferenceNumber(e.target.value);
      dispatch(updateContractData({ referenceNumber: e.target.value }));
    },
    [referenceNumber],
  );
  const handleChangeTypeContract = useCallback(
    (e) => {
      setTypeContractId(e.target.value);
      dispatch(updateContractData({ typeContractId: e.target.value }));
    },
    [typeContractId],
  );
  const handleChangeEffectiveDate = useCallback(
    (e) => {
      setEffectiveDate(e.target.value);
      dispatch(updateContractData({ effectiveDate: e.target.value }));
    },
    [effectiveDate],
  );
  const handleChangeExtensionDate = useCallback(
    (e) => {
      setExtensionDate(e.target.value);
      dispatch(updateContractData({ extensionDate: e.target.value }));
    },
    [extensionDate],
  );
  const handleChangeExpireDate = useCallback(
    (e) => {
      setExpireDate(e.target.value);
      dispatch(updateContractData({ expireDate: e.target.value }));
    },
    [expireDate],
  );
  const handleChangeSignedDate = useCallback(
    (e) => {
      setSignedDate(e.target.value);
      dispatch(updateContractData({ signedDate: e.target.value }));
    },
    [signedDate],
  );

  const handleChangePaymentTermDate = useCallback(
    (e) => {
      setPaymentTermDate(e.target.value);
      dispatch(updateContractData({ paymentTermDate: e.target.value }));
    },
    [paymentTermDate],
  );
  const handleChangeNote = useCallback(
    (e) => {
      setNote(e.target.value);
      dispatch(updateContractData({ note: e.target.value }));
    },
    [note],
  );
  const handleChangeUserShare = useCallback(
    (value) => {
      setArrUserCodeShare(value);
      dispatch(updateContractData({ arrUserCodeShare: value }));
    },
    [arrUserCodeShare],
  );
  const handleChangeSummaryNote = useCallback(
    (e) => {
      setSummaryNote(e.target.value);
      dispatch(updateContractData({ summaryNote: e.target.value }));
    },
    [summaryNote],
  );

  return (
    <>
      {!selected?.isNew && (
        <Box sx={{ marginBottom: '10px', marginTop: '10px' }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <b>Khách hàng: </b>{' '}
              <span style={{ color: 'blue' }}>{selected?.clientCompanyName}</span>
            </Grid>
            <Grid item xs={3}>
              <b>Trạng thái: </b>{' '}
              {CONTRACT_STATUS_OPTIONS[selected?.contractStatus] && (
                <Chip
                  size="small"
                  label={CONTRACT_STATUS_OPTIONS[selected?.contractStatus]?.label}
                  color={CONTRACT_STATUS_OPTIONS[selected?.contractStatus]?.color}
                  sx={{ padding: '5px 10px' }}
                />
              )}
            </Grid>
            {selected?.approvalDate && (
              <Grid item xs={3}>
                <b>Ngày phê duyệt: </b>{' '}
                <span style={{ color: 'red' }}>{Utils.formatDateFn(selected?.approvalDate)}</span>
              </Grid>
            )}

            <Grid item xs={2}>
              <b>Ngày tạo: </b>{' '}
              <span style={{ color: 'red' }}>{Utils.formatDateFn(selected?.createdOn)}</span>
            </Grid>
          </Grid>
        </Box>
      )}

      <ContainerHeaderFormDetail>
        <Box sx={{ flexGrow: 1, padding: '15px' }}>
          <Grid container spacing={1}>
            {selected?.isNew && (
              <Grid item xs={4} sm={6} md={3}>
                <Selection
                  required
                  sxInput={{ padding: '0px', margin: '0px' }}
                  error={!clientCompanyId}
                  helperText={!clientCompanyId && 'Bắt buộc (*)'}
                  label="Khách hàng"
                  name="clientCompanyId"
                  onChange={handleChangeClientCompany}
                  value={clientCompanyId}
                  syncData={ApiServices.getClientCompanies}
                />
              </Grid>
            )}
            <Grid item xs={4} sm={6} md={3}>
              <TextInput
                required
                error={!contractNumber}
                helperText={!contractNumber && 'Bắt buộc (*)'}
                label="Số hợp đồng"
                name="contractNumber"
                onChange={handleChangeContractNumber}
                value={contractNumber}
              />
            </Grid>
            <Grid item xs={4} sm={6} md={3}>
              <TextInput
                required
                error={!referenceNumber}
                helperText={!referenceNumber && 'Bắt buộc (*)'}
                label="Số tham chiếu"
                name="referenceNumber"
                onChange={handleChangeReferenceNumber}
                value={referenceNumber}
              />
            </Grid>
            <Grid item xs={4} sm={6} md={3}>
              <Selection
                required
                sxInput={{ padding: '0px', margin: '0px' }}
                error={!typeContractId}
                helperText={!typeContractId && 'Bắt buộc (*)'}
                label="Loại hợp đồng"
                name="typeContractId"
                onChange={handleChangeTypeContract}
                value={typeContractId}
                syncData={ApiServices.getContractTypes}
              />
            </Grid>
            <Grid item xs={4} sm={6} md={3}>
              <TextInput
                required
                error={!effectiveDate}
                helperText={!effectiveDate && 'Bắt buộc (*)'}
                label="Ngày hiệu lực"
                name="effectiveDate"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChangeEffectiveDate}
                value={effectiveDate}
              />
            </Grid>
            <Grid item xs={4} sm={6} md={3}>
              <TextInput
                required
                error={!extensionDate}
                helperText={!extensionDate && 'Bắt buộc (*)'}
                label="Ngày gia hạn"
                name="extensionDate"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChangeExtensionDate}
                value={extensionDate}
              />
            </Grid>
            <Grid item xs={4} sm={6} md={3}>
              <TextInput
                required
                error={!expireDate}
                helperText={!expireDate && 'Bắt buộc (*)'}
                label="Ngày kết thúc"
                name="expireDate"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChangeExpireDate}
                value={expireDate}
              />
            </Grid>
            <Grid item xs={4} sm={6} md={3}>
              <TextInput
                label="Ngày ký"
                name="signedDate"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChangeSignedDate}
                value={signedDate}
              />
            </Grid>

            <Grid item xs={4} sm={6} md={3}>
              <TextInput
                label="Hạn thanh toán"
                name="paymentTermDate"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChangePaymentTermDate}
                value={paymentTermDate}
              />
            </Grid>
            <Grid item xs={8} sm={12} md={6}>
              <TextInput
                label="Tóm tắt"
                name="summaryNote"
                onChange={handleChangeSummaryNote}
                value={summaryNote}
              />
            </Grid>
            <Grid item xs={4} sm={6} md={3}>
              <TextInput label="Ghi chú" name="note" onChange={handleChangeNote} value={note} />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <SimpleComboBox
                multiple
                disableCloseOnSelect
                label="Chia sẻ cho"
                sx={{ marginTop: '8px' }}
                value={arrUserCodeShare}
                keyValue="code"
                keyFieldView="name"
                onChange={handleChangeUserShare}
                syncData={ApiServices.getUsers}
              />
            </Grid>
          </Grid>
        </Box>
      </ContainerHeaderFormDetail>
    </>
  );
};

export { InformationGeneral };
