import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl'; //FormControl
import Checkbox from '@mui/material/Checkbox';
import { useEffect } from 'react';

export const CheckboxManager = ({
  label = '',
  name = '',
  options = [],
  value = [],
  onChange = null,
}) => {
  const [items, setItems] = React.useState([]);
  const [state, setState] = React.useState({});

  useEffect(() => {
    setItems(options);
    setState(() => {
      const newState = {};
      options.forEach((item) => {
        newState[item.id] = value.includes(item.id);
      });
      return newState;
    });
  }, [options?.length]);

  React.useEffect(() => {
    setState(() => {
      const newState = {};
      items.forEach((item) => {
        newState[item.id] = value.includes(item.id) || false;
      });
      return newState;
    });
  }, [value?.length]);

  const onChangeCheckbox = React.useCallback(
    (e, index, item) => {
      setState((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
      let res = value;
      if (e.target.checked) {
        res[index] = item.id;
      } else {
        res = value.filter((v) => v !== e.target.name);
      }
      onChange && onChange(res);
    },
    [value],
  );
  return (
    <FormControl component="fieldset">
      <FormLabel>{label}</FormLabel>
      <FormGroup>
        {items.map((item, index) => {
          return (
            <FormControlLabel
              key={`checkbox-group-${item.id}`}
              control={
                <Checkbox
                  checked={Boolean(state[item.id])}
                  onChange={(e) => onChangeCheckbox(e, index, item)}
                  name={item.id}
                />
              }
              label={item.name || item.code}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};
