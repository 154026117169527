import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Box, Grid } from '@mui/material';

export const HeaderFreight = ({ dataCustoms }) => {
  const ARRAY_HEADER = [
    {
      title: 'Địa điểm đi:',
      value: dataCustoms?.objLocationFrom?.name,
      xs: 3,
    },
    {
      title: 'Khu vực đi:',
      value: dataCustoms?.objAreaFrom?.name,
      xs: 3,
    },
    {
      title: 'Địa điểm đến:',
      value: dataCustoms?.objLocationTo?.name,
      xs: 3,
    },
    {
      title: 'Khu vực đến:',
      value: dataCustoms?.objAreaTo?.name,
      xs: 3,
    },
  ];

  return (
    <>
      <ContainerItemService>
        <i>
          {dataCustoms?.serviceName} - {dataCustoms?.subServiceName}
        </i>
      </ContainerItemService>
      <Box sx={{ padding: '10px 20px 10px 20px', fontSize: '14px' }}>
        <Grid container spacing={3}>
          {ARRAY_HEADER.map((item, index) => (
            <Grid item xs={item?.xs} key={`${item?.title}-${index}`}>
              <Typography component="b" variant="string">
                {item?.title}
              </Typography>
              <Typography component="div" variant="string" sx={{ color: 'blue' }}>
                {item?.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export const ContainerItemService = styled(Box)(({ theme }) => ({
  height: 'fit-content',
  backgroundColor: 'rgba(6, 95, 212, 0.1)',
  borderRadius: '12px',
  display: 'flex',
  marginBottom: '10px',
  marginTop: '5px',
  padding: '10px 20px',
}));
