import * as React from 'react';
import { TextInput, Selection, ComboBox } from 'components';
import { Grid, Box } from '@mui/material';
import ApiServices from 'services';

export const InfomationComplaint = ({
  errors,
  handleBlur,
  handleChange,
  touched,
  values,
  onHandleChangeFn,
  client,
  clients,
  clientComplants,
  clientComplant,
  onHandleChangeClientComplant,
}) => {
  return (
    <>
      <Box
        sx={{
          marginRight: '10px',
        }}
      >
        <TextInput
          required
          error={Boolean(touched.code && errors.code)}
          helperText={touched.code && errors.code}
          label="Mã phiếu"
          name="code"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.code}
        />
        <ComboBox
          options={clients}
          label="Khách hàng"
          sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
          keyField="name"
          value={client}
          onChange={(event, newValue) => onHandleChangeFn(event, newValue)}
        />
        {client && (
          <ComboBox
            options={clientComplants}
            label="Người hổ trợ"
            sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
            keyField="fullName"
            value={clientComplant}
            onChange={(event, newValue) => onHandleChangeClientComplant(event, newValue)}
          />
        )}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput
              required
              error={Boolean(touched.problemlevel && errors.problemlevel)}
              helperText={touched.problemlevel && errors.problemlevel}
              label="Cấp độ vấn đề"
              name="problemlevel"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.problemlevel}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              error={Boolean(touched.expirationDate && errors.expirationDate)}
              helperText={touched.expirationDate && errors.expirationDate}
              label="Ngày hết hạn xử lý khiếu nại"
              name="expirationDate"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.expirationDate}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <TextInput
          required
          error={Boolean(touched.causeofComplaint && errors.causeofComplaint)}
          helperText={touched.causeofComplaint && errors.causeofComplaint}
          label="Nguyên nhân"
          name="causeofComplaint"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.causeofComplaint}
        />
        <TextInput
          error={Boolean(touched.description && errors.description)}
          helperText={touched.description && errors.description}
          label="Mô tả"
          name="description"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.description}
        />
      </Box>
    </>
  );
};
