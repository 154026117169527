import React from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { HandleFormStep, TextInput, Popup, ComboBox } from 'components';
import {
  Box,
  Button,
  Stack,
  Typography,
  Chip,
  Step,
  Stepper,
  StepLabel,
  Toolbar,
} from '@mui/material';
import { Formik } from 'formik';
import ApiServices from 'services';
import Storage from 'storage';
import { InfomationComplaint } from './info-client-complaint';
import { ContentComplaint } from './content-client-complaint';
import { PopupCancel } from './popup-cancel';

import { VscNotebookTemplate } from 'react-icons/vsc';

const steps = ['Thông tin chi tiết', 'Nội dung giải quyết'];
const FORM_WIDTH = '600px';
export const FormDetail = ({ selected, setSelected, initData, ...props }) => {
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [confirmCancel, setConfirmCancel] = React.useState(false);
  const [valuesCancel, setValuesCancel] = React.useState({});
  const [statusCancel, setStatusCancel] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [clients, setClients] = React.useState([]);
  const [client, setClient] = React.useState(null);
  const [clientComplants, setClientComplants] = React.useState([]);
  const [clientComplant, setClientComplant] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [verifier, setVerifier] = React.useState(null);
  const [mainFollowers, setMainFollowers] = React.useState(null);
  const [proposer, setProposer] = React.useState(null);
  const [approvedby, setApprovedby] = React.useState(null);
  const [handlerCode, setHandlerCode] = React.useState(null);
  const [checker, setChecker] = React.useState(null);

  const buttonSubmit = !client || !clientComplant;

  const getClients = async () => {
    try {
      const { data } = await ApiServices.getClientCompanies();
      setClients(data);
      if (!selected.isNew) {
        const client = data.find((item) => item.id === selected.clientCompanyId);
        setClient(client);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await ApiServices.getUsers();
      setUsers(data);
      if (!selected.isNew) {
        const dataVrifyer = data.find((item) => item.code === selected.verifier);
        setVerifier(dataVrifyer);
        const datMainFollowers = data.find((item) => item.code === selected.mainFollowers);
        setMainFollowers(datMainFollowers);
        const dataProposer = data.find((item) => item.code === selected.proposer);
        setProposer(dataProposer);
        const dataApprovedby = data.find((item) => item.code === selected.approvedby);
        setApprovedby(dataApprovedby);
        const dataHandlerCode = data.find((item) => item.code === selected.handlerCode);
        setHandlerCode(dataHandlerCode);
        const dataChecker = data.find((item) => item.code === selected.checker);
        setChecker(dataChecker);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (selected) {
      getClients();
      getUsers();
    } else {
      setClients([]);
      setClient(null);
      setClientComplants([]);
      setClientComplant(null);
      setUsers([]);
      setVerifier(null);
      setMainFollowers(null);
      setProposer(null);
      setApprovedby(null);
      setHandlerCode(null);
      setChecker(null);
    }
  }, [selected]);

  const getFullListByClientCompanyId = async (idSend) => {
    try {
      const { data } = await ApiServices.getFullListByClientCompanyId(idSend);
      setClientComplants(data);
      if (!selected.isNew) {
        const clientComplaint = data.find((item) => item.id === selected.clientId);
        setClientComplant(clientComplaint);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (client && client?.id) {
      getFullListByClientCompanyId(client.id);
    }
  }, [client]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onHandleSubmit = () => {};

  React.useEffect(() => {
    setActiveStep(0);
  }, [selected]);

  const onHandleChangeFn = async (event, newValue) => {
    setClient(newValue);
  };
  const onHandleChangeClientComplant = async (event, newValue) => {
    setClientComplant(newValue);
  };
  const onHandleChangeverifier = async (event, newValue) => {
    setVerifier(newValue);
  };
  const onHandleChangeMainFollowers = async (event, newValue) => {
    setMainFollowers(newValue);
  };
  const onHandleChangeProposer = async (event, newValue) => {
    setProposer(newValue);
  };
  const onHandleChangApprovedby = async (event, newValue) => {
    setApprovedby(newValue);
  };
  const onHandleChangeHandlerCode = async (event, newValue) => {
    setHandlerCode(newValue);
  };
  const onHandleChangeChecker = async (event, newValue) => {
    setChecker(newValue);
  };

  //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  const createFn = async (values, { setSubmitting }) => {
    var valueSend = {
      code: values?.code,
      clientCompanyId: client?.id || '',
      clientId: clientComplant?.id || '',
      problemlevel: values?.problemlevel,
      expirationDate: values?.expirationDate,
      causeofComplaint: values?.causeofComplaint,
      description: values?.description,
      verification: {
        requestVerification: values?.requestVerification,
        rateVerification: +values.rateVerification || 0,
        verifier: verifier?.code || '',
        verificationDate: values?.verificationDate || null,
        responsibleEntityId: values?.responsibleEntityId,
        mainFollowers: mainFollowers?.code || '',
        proposer: proposer?.code || '',
      },
      solution: values?.solution,
      approved: {
        approvedNote: values?.approvedNote,
        approvedby: approvedby?.code || '',
        approvedOn: values?.approvedOn || null,
      },
      process: {
        contentsProcessed: values?.contentsProcessed,
        handlerCode: handlerCode?.code || '',
        processingDate: values?.processingDate || null,
      },
      check: {
        checkNote: values?.checkNote,
        checker: checker?.code || '',
        checkDate: values?.checkDate || null,
      },
      arising: {
        arisingtype: 0,
        moneyArising: 0,
      },
    };

    const infoClientDetail = await ApiServices.getClientDetail(clientComplant?.id);

    if (infoClientDetail) {
      valueSend.status = 0;
      valueSend.clientCompanyId = infoClientDetail?.data?.[0]?.clientCompanyId;
      valueSend.companyId = Storage?.user?.CompanyId;
      setSubmitting(true);
      try {
        const { status } = await ApiServices.createClientComplaint(valueSend);
        if (status === 200) {
          window.UIMessage?.success('Thêm mới thành công');
          setSelected(null);
          initData();
        } else {
          window.UIMessage?.error('Thêm mới thất bại !!!');
        }
      } catch (error) {
        console.log(error);
      }
      setSubmitting(false);
    }
  };

  const updateFn = async (values, { setSubmitting }) => {
    // delete values.updatedOn;
    var valueSend = {
      ...values,
      id: values.id,
      status: values.status,
      code: values.code,
      clientCompanyId: client?.id || '',
      clientId: clientComplant?.id || '',
      problemlevel: values.problemlevel,
      expirationDate: values.expirationDate,
      causeofComplaint: values.causeofComplaint,
      description: values.description,
      verification: {
        requestVerification: values.requestVerification,
        rateVerification: +values.rateVerification || 0,
        verifier: verifier?.code || '',
        verificationDate: values.verificationDate || null,
        responsibleEntityId: values.responsibleEntityId,
        mainFollowers: mainFollowers?.code || '',
        proposer: proposer?.code || '',
      },
      solution: values.solution,
      approved: {
        approvedNote: values.approvedNote,
        approvedby: approvedby?.code || '',
        approvedOn: values.approvedOn || null,
      },
      process: {
        contentsProcessed: values.contentsProcessed,
        handlerCode: handlerCode?.code || '',
        processingDate: values.processingDate || null,
      },
      check: {
        checkNote: values.checkNote,
        checker: checker?.code || '',
        checkDate: values.checkDate || null,
      },
      arising: {
        arisingtype: +values?.arisingtype,
        moneyArising: +values?.arisingtype,
      },
    };

    setSubmitting(true);
    try {
      const { data } = await ApiServices.updateClientComplaint(valueSend.id, valueSend);
      if (data) {
        window.UIMessage?.success('Cập nhật thành công');
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Cập nhật thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const updateStatusComplaintFn = async (values, status, setSubmitting) => {
    values.status = status;
    if (status === 2) {
      values.approvedby = Storage?.user?.Name;
      values.approvedOn = moment().format();
    }
    if (values.updatedOn === '0001-01-01T00:00:00+07:00') {
      delete values.updatedOn;
    }
    return updateFn(values, { setSubmitting });
  };

  const onHandleCancelStatus = (values, status) => {
    setValuesCancel(values);
    setStatusCancel(status);
    setConfirmCancel(true);
  };

  function showStatusClient(status) {
    switch (status) {
      case 0:
        return <span style={{ color: '#8E7F7F' }}>(Mới)</span>;
      case 1:
        return <span style={{ color: '#03a9f4' }}>(Chờ duyệt)</span>;
      case 2:
        return <span style={{ color: '#DE8971' }}>(Đang xử lý)</span>;
      case 3:
        return <span style={{ color: 'green' }}>(Hoàn thành)</span>;
      case 4:
        return <span style={{ color: 'red' }}>(Hủy)</span>;
      case 10:
        return <span style={{ color: 'red' }}>(Hủy)</span>;
      default:
        break;
    }
  }

  const onHandleExportTemplate = async (id) => {
    setLoading(true);
    try {
      const response = await ApiServices.exportTemplateClientComplaint(id);
      if (response.data) {
        const outputFilename = `Bieu_mau_khieu_nai_${moment().format('DD-MM-YYYY')}.docx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        window.UIMessage?.success('Tải biểu mẫu thành công');
        link.remove();
      }
    } catch (err) {
      console.warn('import excel - Lead new', err);
      window.UIMessage?.error('Tải biểu mẫu thất bại !!!');
    }
    setLoading(false);
  };

  return (
    <>
      <HandleFormStep {...{ selected, setSelected, width: FORM_WIDTH }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <Typography
            color="primary"
            variant="h6"
            noWrap
            component="div"
            sx={{
              marginBottom: '10px',
            }}
          >
            {selected?.isNew ? 'Tạo mới' : `Mã: ${selected?.code}`}
          </Typography>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end', mr: 2 }}>
            <Button
              variant="outlined"
              startIcon={<VscNotebookTemplate />}
              sx={{
                textTransform: 'none',
                borderRadius: '15px',
                marginLeft: '5px',
                color: '#6C757D',
                border: '1px solid rgba(0, 0, 0, 0.4)',
                padding: '4px 15px',
                '.MuiButton-startIcon': {
                  marginRight: '4px',
                },
              }}
              onClick={() => onHandleExportTemplate(selected.id)}
            >
              Biểu mẫu
            </Button>
          </Box>
        </Toolbar>

        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <React.Fragment>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Quay lại
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleNext} disabled={activeStep === 1}>
              Tiếp tục
            </Button>
            {/* {activeStep === 1 ? (
              <Button onClick={onHandleSubmit}>Thêm mới</Button>
            ) : (
              <Button onClick={handleNext}>Tiếp tục</Button>
            )} */}
          </Box>
        </React.Fragment>
        <Box sx={{ marginTop: '20px' }}>
          <Formik
            initialValues={{
              code: '',
              // clientCompanyId: '',
              // addressClient: '',
              // phoneClient: '',
              // faxClient: '',
              // clientId: '',
              problemlevel: '',
              expirationDate: '',
              causeofComplaint: '',
              description: '',
              //verification
              requestVerification: '',
              rateVerification: '',
              verifier: '', // user
              verificationDate: '',

              //verification
              responsibleEntityId: '', // department
              mainFollowers: '', // user
              proposer: '',

              //approved
              solution: '', // verify
              approvedNote: '', // Noi dung
              approvedby: '', // Nguoi phe duyet
              approvedOn: '', // Ngày phe duyet

              // process
              contentsProcessed: '', // Tien hanh xu ly
              handlerCode: '', // User
              processingDate: '', // Ngay xu ly

              // Check
              checkNote: '', // Noi dung kiem tra
              checker: '', // Người kiểm tra
              checkDate: '', // Ngày kiểm tra

              // arising Phat sinh
              arisingtype: '', // Loai phat sinh
              moneyArising: '', // chi phi phat sinh

              ...selected,
            }}
            validationSchema={Yup.object().shape({
              code: Yup.string().max(255).required('Vui lòng nhập mã phiếu !!!'),
              // clientId: Yup.string().max(255).required('Vui lòng chọn khách hàng !!!'),
              problemlevel: Yup.string().max(255).required('Vui lòng nhập cấp độ vấn đề !!!'),
              expirationDate: Yup.string()
                .max(255)
                .required('Vui lòng nhập ngày hết hạn xử lý khiếu nại !!!'),
              causeofComplaint: Yup.string().max(255).required('Vui lòng nhập nguyên nhân !!!'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              if (selected?.isNew) {
                createFn(values, { setSubmitting });
              } else {
                updateFn(values, { setSubmitting });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                {activeStep === 0 ? (
                  <Box>
                    <Box sx={{ maxHeight: 'calc(100vh - 240px)', overflow: 'auto' }}>
                      <InfomationComplaint
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touched={touched}
                        values={values}
                        onHandleChangeFn={(event, newValue) => onHandleChangeFn(event, newValue)}
                        clients={clients}
                        client={client}
                        clientComplants={clientComplants}
                        clientComplant={clientComplant}
                        onHandleChangeClientComplant={(event, newValue) =>
                          onHandleChangeClientComplant(event, newValue)
                        }
                      />
                    </Box>
                    <Box sx={{ py: 2 }}>
                      <Stack direction="row" spacing={2}>
                        <Button
                          color="primary"
                          disabled={isSubmitting || buttonSubmit}
                          fullWidth
                          size="small"
                          type="submit"
                          variant="contained"
                        >
                          {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                        </Button>
                        {selected?.status === 1 || selected?.status === 0 ? (
                          <Button
                            color="error"
                            disabled={isSubmitting}
                            fullWidth
                            size="small"
                            variant="contained"
                            onClick={() => updateStatusComplaintFn(values, 10, setSubmitting)}
                          >
                            Hủy
                          </Button>
                        ) : null}
                        {selected?.status === 0 ? (
                          <Button
                            color="info"
                            disabled={isSubmitting}
                            fullWidth
                            size="small"
                            variant="contained"
                            onClick={() => updateStatusComplaintFn(values, 1, setSubmitting)}
                          >
                            Chờ Duyệt
                          </Button>
                        ) : null}
                        {selected?.status === 1 ? (
                          <Button
                            color="success"
                            disabled={isSubmitting}
                            fullWidth
                            size="small"
                            variant="contained"
                            onClick={() => updateStatusComplaintFn(values, 2, setSubmitting)}
                          >
                            Duyệt
                          </Button>
                        ) : null}
                        {selected?.status === 2 ? (
                          <>
                            <Button
                              color="error"
                              disabled={isSubmitting}
                              fullWidth
                              size="small"
                              variant="contained"
                              // onClick={() => updateStatusComplaintFn(values, 10, setSubmitting)}
                              onClick={() => onHandleCancelStatus(values, 4)}
                            >
                              Hủy
                            </Button>
                            <Button
                              color="success"
                              disabled={isSubmitting}
                              fullWidth
                              size="small"
                              variant="contained"
                              onClick={() => updateStatusComplaintFn(values, 3, setSubmitting)}
                            >
                              Hoàn thành
                            </Button>
                          </>
                        ) : null}
                        {selected?.status === 10 ? (
                          <Button
                            color="success"
                            disabled={isSubmitting}
                            fullWidth
                            size="small"
                            variant="contained"
                            onClick={() => updateStatusComplaintFn(values, 0, setSubmitting)}
                          >
                            Mở Lại
                          </Button>
                        ) : null}
                      </Stack>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Box sx={{ maxHeight: 'calc(100vh - 240px)', overflow: 'auto' }}>
                      <ContentComplaint
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touched={touched}
                        values={values}
                        users={users}
                        verifier={verifier}
                        onHandleChangeverifier={(event, newValue) =>
                          onHandleChangeverifier(event, newValue)
                        }
                        mainFollowers={mainFollowers}
                        onHandleChangeMainFollowers={(event, newValue) =>
                          onHandleChangeMainFollowers(event, newValue)
                        }
                        proposer={proposer}
                        onHandleChangeProposer={(event, newValue) =>
                          onHandleChangeProposer(event, newValue)
                        }
                        approvedby={approvedby}
                        onHandleChangApprovedby={(event, newValue) =>
                          onHandleChangApprovedby(event, newValue)
                        }
                        handlerCode={handlerCode}
                        onHandleChangeHandlerCode={(event, newValue) =>
                          onHandleChangeHandlerCode(event, newValue)
                        }
                        checker={checker}
                        onHandleChangeChecker={(event, newValue) =>
                          onHandleChangeChecker(event, newValue)
                        }
                      />
                    </Box>
                    <Box sx={{ py: 2 }}>
                      <Stack direction="row" spacing={2}>
                        <Button
                          color="primary"
                          disabled={isSubmitting || buttonSubmit}
                          fullWidth
                          size="small"
                          type="submit"
                          variant="contained"
                        >
                          {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                        </Button>
                        {selected?.status === 1 || selected?.status === 0 ? (
                          <Button
                            color="error"
                            disabled={isSubmitting}
                            fullWidth
                            size="small"
                            variant="contained"
                            onClick={() => updateStatusComplaintFn(values, 10, setSubmitting)}
                          >
                            Hủy
                          </Button>
                        ) : null}
                        {selected?.status === 0 ? (
                          <Button
                            color="info"
                            disabled={isSubmitting}
                            fullWidth
                            size="small"
                            variant="contained"
                            onClick={() => updateStatusComplaintFn(values, 1, setSubmitting)}
                          >
                            Chờ Duyệt
                          </Button>
                        ) : null}
                        {selected?.status === 1 ? (
                          <Button
                            color="success"
                            disabled={isSubmitting}
                            fullWidth
                            size="small"
                            variant="contained"
                            onClick={() => updateStatusComplaintFn(values, 2, setSubmitting)}
                          >
                            Duyệt
                          </Button>
                        ) : null}
                        {selected?.status === 2 ? (
                          <>
                            <Button
                              color="error"
                              disabled={isSubmitting}
                              fullWidth
                              size="small"
                              variant="contained"
                              // onClick={() => updateStatusComplaintFn(values, 10, setSubmitting)}
                              onClick={() => onHandleCancelStatus(values, 4)}
                            >
                              Hủy
                            </Button>

                            <Button
                              color="success"
                              disabled={isSubmitting}
                              fullWidth
                              size="small"
                              variant="contained"
                              onClick={() => updateStatusComplaintFn(values, 3, setSubmitting)}
                            >
                              Hoàn thành
                            </Button>
                          </>
                        ) : null}
                        {selected?.status === 10 ? (
                          <Button
                            color="success"
                            disabled={isSubmitting}
                            fullWidth
                            size="small"
                            variant="contained"
                            onClick={() => updateStatusComplaintFn(values, 0, setSubmitting)}
                          >
                            Mở Lại
                          </Button>
                        ) : null}
                      </Stack>
                    </Box>
                  </Box>
                )}
              </form>
            )}
          </Formik>
        </Box>
      </HandleFormStep>
      <Popup
        title="Hủy phiếu khiếu nại"
        open={confirmCancel}
        closePopup={() => setConfirmCancel(false)}
        body={
          <PopupCancel
            setConfirmCancel={setConfirmCancel}
            setSelected={setSelected}
            valuesCancel={valuesCancel}
            statusCancel={statusCancel}
            initData={initData}
          />
        }
      />
    </>
  );
};
