import { TextField } from '@mui/material';
export const TextInput = ({
  value,
  name,
  onBlur,
  onChange,
  error,
  helperText,
  label,
  type = 'text',
  size = 'small',
  margin = 'dense',
  fullWidth = true,
  variant = 'outlined',
  multiline = false,
  maxRows = 5,
  InputLabelProps,
  disabled = false,
  required,
  rows,
  sx,
  ...props
}) => {
  return (
    <TextField
      {...{
        value,
        name,
        error,
        helperText,
        label: required ? label + ' (*)' : label,
        onChange,
        onBlur,
        fullWidth,
        type,
        size,
        variant,
        margin,
        multiline,
        // maxRows,
        InputLabelProps,
        disabled,
        rows,
        sx,

        // required,
      }}
    />
  );
};
