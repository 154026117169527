import * as React from 'react';
import { Box, Divider, Typography, Grid } from '@mui/material';
import Utils from 'commons/utils';
import ApiServices from 'services';

const TabEmailManagement = ({ clientCareHistoryItem }) => {
  const [mails, setMails] = React.useState([]);

  const getMailByClientCareHistory = async () => {
    try {
      const { data } = await ApiServices.getMailByClientCareHistory(clientCareHistoryItem.id);
      if (data) {
        setMails(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getMailByClientCareHistory();
  }, []);
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100px',
        backgroundColor: 'rgb(234, 238, 243)',
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        borderRadius: '10px',
        fontSize: '12px',
      }}
    >
      <Typography mt={2} align="center" component="div" variant="h6" sx={{ marginTop: '0px' }}>
        Lịch sử gửi mail
      </Typography>
      <Divider />
      {/* header */}
      <Grid container>
        <Grid item xs={2} sx={{ padding: 1, textAlign: 'center' }}>
          <Typography align="center" component="b" variant="string">
            Ngày gửi
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}>
          <Typography align="center" component="b" variant="string">
            Tiêu đề
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}>
          <Typography align="center" component="b" variant="string">
            Nội dung
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}>
          <Typography align="center" component="b" variant="string">
            Người liên hệ/ Đối tác
          </Typography>
        </Grid>
      </Grid>
      {/* body - mapdata */}
      {mails.map((item) => (
        <div key={item.id}>
          <Divider variant="middle" />
          <Grid container>
            <Grid item xs={2} sx={{ padding: 1, textAlign: 'center' }}>
              {Utils.formatDateHourFn(item?.createdOn)}
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
            >
              {item?.subject.split('[')[0]}
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
            >
              {Utils.htmlToString(item?.body)}
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ padding: 1, borderLeft: '1px solid #C0C0C0', textAlign: 'center' }}
            >
              {item?.clientName}
            </Grid>
          </Grid>
        </div>
      ))}
    </Box>
  );
};

export { TabEmailManagement };
