import React, { useState, useMemo } from 'react';
import { Box, ToggleButton, Button } from '@mui/material';
import {
  BsEye,
  BsSave,
  BsBoxArrowInUpRight,
  BsPrinter,
  BsXCircle,
  BsCheck2Circle,
  BsEnvelope,
} from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { ModalFormDetail } from 'components';
import { LoadingView } from 'components/loading-view';
import { InformationGeneral } from './information-general';
import { QuoteManagement } from './quote-management';
import { ContentContract } from './content-contract';
import ApiServices from 'services';
import { CONTRACT_STATUS } from 'commons/constants';
import Utils from 'commons/utils';
import {
  ContainerContentBody,
  StyledToggleButtonGroup,
  BtnViewReportContract,
  BtnCreateContract,
  BtnRequestContract,
  BtnPrintContract,
  BtnApproveContract,
  BtnRejectContract,
} from './styles';

export const FormDetail = ({ selected, onCloseModal, initData }) => {
  const { quoteIdList, contractData } = useSelector((state) => state.contract);
  const [selectActionContract, setSelectActionContract] = useState(false);
  const [loading, setLoading] = useState(false);

  const disabledSubmit = useMemo(() => {
    if (selected?.isNew) return false;
    if (selected?.contractStatus === CONTRACT_STATUS.ORIGIN) return false;
    return true;
  }, [selected]);

  const handleClose = () => {
    onCloseModal();
  };
  const handleActionContract = (event, action) => {
    if (action === null) return;
    setSelectActionContract((pre) => action);
  };

  const renderTableQuote = () => {
    return <QuoteManagement clientCompanyId={contractData?.clientCompanyId} />;
  };

  const createQuote = async () => {
    setLoading(true);
    const dataSend = {
      ...contractData,
      arrQuotesId: quoteIdList,
    };

    ApiServices.createContractQuote(dataSend)
      .then((res) => {
        if (res?.status === 200) {
          handleClose();
          initData();
        }
      })
      .catch((err) => {
        console.log('Create contract error: ', err);
      });
    setLoading(false);
  };
  const updateQuote = async (contractStatus, approvalDate) => {
    setLoading(true);
    const dataSend = {
      ...contractData,
      contractStatus,
      approvalDate,
    };

    ApiServices.updateContractQuote(dataSend?.id, dataSend)
      .then((res) => {
        if (res?.status === 200) {
          handleClose();
          initData();
        }
      })
      .catch((err) => {
        console.log('Create contract error: ', err);
      });

    setLoading(false);
  };

  const handleSubmit = (contractStatus, approvalDate = null) => {
    if (selected?.isNew) {
      return createQuote(contractStatus);
    } else {
      return updateQuote(contractStatus, approvalDate);
    }
  };

  const renderSubmit = () => {
    return (
      <Box sx={{ display: 'flex', py: 2 }}>
        <Box sx={{ marginLeft: 'auto' }}>
          <BtnViewReportContract
            sx={{ marginRight: '15px' }}
            color="info"
            variant="outlined"
            startIcon={<BsEye />}
            // onClick={() => onHandleSubmit(WAIT_RES_CHECK_PRICE)}
          >
            Xem trước
          </BtnViewReportContract>
          <BtnPrintContract
            sx={{ marginRight: '15px' }}
            color="info"
            variant="contained"
            startIcon={<BsPrinter />}
            // onClick={() => onHandleSubmit(WAIT_RES_CHECK_PRICE)}
          >
            In hợp đồng
          </BtnPrintContract>
          {(selected?.isNew || selected?.contractStatus === CONTRACT_STATUS.ORIGIN) && (
            <>
              <BtnCreateContract
                sx={{ marginRight: '15px' }}
                color="primary"
                variant="contained"
                startIcon={<BsSave size={16} />}
                onClick={() => handleSubmit(CONTRACT_STATUS.ORIGIN)}
                disabled={disabledSubmit}
              >
                {selected?.isNew ? 'Tạo hợp đồng' : 'Cập nhật'}
              </BtnCreateContract>
              <BtnRequestContract
                color="success"
                variant="contained"
                startIcon={<BsBoxArrowInUpRight size={20} />}
                onClick={() => handleSubmit(CONTRACT_STATUS.REQUEST)}
                disabled={disabledSubmit}
              >
                Trình ký
              </BtnRequestContract>
            </>
          )}
          {selected?.contractStatus === CONTRACT_STATUS.REQUEST && (
            <>
              <BtnApproveContract
                sx={{ marginRight: '15px' }}
                color="success"
                variant="contained"
                startIcon={<BsCheck2Circle size={20} />}
                onClick={() =>
                  handleSubmit(CONTRACT_STATUS.APPROVED, Utils.convertToDateFormat(new Date()))
                }
              >
                Xác nhận
              </BtnApproveContract>
              <BtnRejectContract
                color="error"
                variant="contained"
                startIcon={<BsXCircle size={20} />}
                onClick={() => handleSubmit(CONTRACT_STATUS.CANCEL)}
              >
                Hủy
              </BtnRejectContract>
            </>
          )}
        </Box>
      </Box>
    );
  };
  const renderContentContract = () => {
    return (
      <>
        <ContentContract quoteIdList={quoteIdList} selected={selected} />
        {renderSubmit()}
      </>
    );
  };

  return (
    <>
      <ModalFormDetail
        open={selected}
        onCloseModal={handleClose}
        title={selected?.isNew ? 'Tạo hợp đồng' : 'Thông tin chi tiết'}
      >
        <Box>
          <i style={{ color: 'blue' }}>Thông tin chung:</i>
        </Box>
        <InformationGeneral selected={selected} />
        <Box>
          <i style={{ color: 'blue' }}>Nội dung hợp đồng:</i>
        </Box>
        {selected?.isNew ? (
          <>
            {contractData?.clientCompanyId && (
              <>
                <Box sx={{ display: 'flex' }}>
                  <Box>
                    <StyledToggleButtonGroup
                      size="small"
                      color="primary"
                      value={selectActionContract}
                      exclusive
                      onChange={handleActionContract}
                      aria-label="Action price"
                    >
                      <ToggleButton value={false} aria-label="find-quote">
                        Tìm báo giá
                      </ToggleButton>
                      <ToggleButton
                        value={true}
                        aria-label="review-contract"
                        disabled={!quoteIdList?.length > 0}
                      >
                        Xem hợp đồng
                      </ToggleButton>
                    </StyledToggleButtonGroup>
                  </Box>
                </Box>
                <ContainerContentBody>
                  {!selectActionContract ? renderTableQuote() : renderContentContract()}
                </ContainerContentBody>
              </>
            )}
          </>
        ) : (
          <ContainerContentBody>{renderContentContract()}</ContainerContentBody>
        )}
        <LoadingView loading={loading} />
      </ModalFormDetail>
    </>
  );
};
