import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[800],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

function ModalFormDetail({ title, open, onCloseModal, backgroundModal = 'white', children }) {
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (open) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [open]);

  const closePopup = () => {
    setOpenModal(false);
    onCloseModal();
  };

  return (
    <Dialog open={openModal} onClose={closePopup} fullWidth={true} maxWidth={false}>
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={closePopup}
        sx={{ backgroundColor: `${backgroundModal}` }}
      >
        {title}
      </BootstrapDialogTitle>
      <DialogContent sx={{ backgroundColor: `${backgroundModal}` }}>{children}</DialogContent>
    </Dialog>
  );
}

export { ModalFormDetail };
