import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Box } from '@mui/material';
import { NumberFormatCustom } from 'components';

const InputItem = React.forwardRef(
  ({ item, index, setValues, keyArray, keyNameOfArray, disabled, isSeaLcl, isAir }, ref) => {
    const [arrayCont, setArrayCont] = React.useState([]);

    React.useImperativeHandle(ref, () => ({
      getAlert() {
        alert('getAlert from Child');
      },
    }));
    React.useEffect(() => {
      setArrayCont(item[keyArray] || []);
    }, [item]);

    return (
      <Container>
        <Box sx={{ width: '300px', marginRight: '10px' }}></Box>
        {arrayCont?.map((el, indContType) => (
          <Box sx={{ width: '120px', marginRight: '10px' }} key={`cont-${indContType}`}>
            <StyledTextFieldPrice
              label={el[keyNameOfArray]}
              variant="outlined"
              type="text"
              size="small"
              InputProps={{
                inputComponent: NumberFormatCustom,
                disabled: true,
              }}
              value={el?.price}
              error={!el?.price}
            />
          </Box>
        ))}
        {arrayCont?.map((el, indContType) => (
          <Box sx={{ width: '120px', marginRight: '10px' }} key={`MakeUp-${indContType}`}>
            <TextFieldMakeUp
              label={`${!isAir ? '%' : ''}Make Up ${!isSeaLcl ? el[keyNameOfArray] : ''}`}
              variant="outlined"
              type="text"
              size="small"
              InputProps={{
                inputComponent: NumberFormatCustom,
                disabled: true,
              }}
              value={el?.makeup}
            />
          </Box>
        ))}
        {arrayCont?.map((el, indContType) => (
          <Box sx={{ width: '120px', marginRight: '10px' }} key={`Price-${indContType}`}>
            <TextFieldPriceCont
              label={`Giá bán ${!isSeaLcl ? el[keyNameOfArray] : ''}`}
              variant="outlined"
              type="text"
              size="small"
              InputProps={{
                inputComponent: NumberFormatCustom,
                disabled: true,
              }}
              value={el?.buyPrice}
            />
            {!el?.buyPrice && <ErrorText>Bắt buộc (*)</ErrorText>}
          </Box>
        ))}
        <StyledTextField
          label="Đơn vị tính"
          variant="outlined"
          type="text"
          size="small"
          value={item?.unitCode}
        />
        <StyledTextField
          label="Remark"
          variant="outlined"
          type="text"
          size="small"
          value={item?.remark}
        />
      </Container>
    );
  },
);
export default InputItem;

const StyledTextFieldPrice = styled(TextField)(({ theme }) => ({
  width: '120px',
}));
const TextFieldMakeUp = styled(TextField)(({ theme }) => ({
  width: '120px',
  backgroundColor: '#FCF2EF',
}));
const TextFieldPriceCont = styled(TextField)(({ theme }) => ({
  width: '120px',
  backgroundColor: 'rgba(6, 95, 212, 0.1)',
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '150px',
  marginRight: '10px',
}));
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '10px',
}));
const ErrorText = styled('div')(({ theme }) => ({
  color: 'red',
  fontSize: '12px',
  marginLeft: '10px',
}));
