import * as React from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { HandleFormDetail, TextInput, Selection, DateTimePicker } from 'components';
import { Box, Button, TextField } from '@mui/material';
import ApiServices from 'services';

const PopupCancel = ({ setConfirmCancel, setSelected, valuesCancel, statusCancel, initData }) => {
  const addCallFn = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const { status } = await ApiServices.updateClientStatusCancel(valuesCancel.id, values);
      if (status === 200) {
        window.UIMessage?.success('Cập nhật trạng thái thành công');
        setConfirmCancel(false);
        setSelected(null);
        initData();
      } else {
        window.UIMessage?.error('Cập nhật trạng thái thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  return (
    <>
      <Formik
        initialValues={{
          cancellationReason: '',
        }}
        validationSchema={Yup.object().shape({
          cancellationReason: Yup.string().max(255).required('Vui lòng nhập lý do hủy !!!'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          addCallFn(values, { setSubmitting });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form onSubmit={handleSubmit} style={{ width: '500px' }}>
            <TextInput
              error={Boolean(touched.cancellationReason && errors.cancellationReason)}
              helperText={touched.cancellationReason && errors.cancellationReason}
              label="Nội dung"
              name="cancellationReason"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.cancellationReason}
              multiline
              rows={4}
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="error"
                disabled={isSubmitting}
                fullWidth
                size="small"
                type="submit"
                variant="contained"
              >
                Hủy
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export { PopupCancel };
