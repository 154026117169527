import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Box } from '@mui/material';
import { NumberFormatCustom } from 'components';

const InputItem = React.forwardRef(
  ({ item, index, setValues, arrSurcharge, showQuantity, disabled }, ref) => {
    const [quantity, setQuantity] = React.useState(0);
    const [makeup, setMakeup] = React.useState(0);
    const [buyPrice, setBuyPrice] = React.useState(0);

    React.useImperativeHandle(ref, () => ({
      getAlert() {
        alert('getAlert from Child');
      },
    }));
    React.useEffect(() => {
      setQuantity(showQuantity ? item?.quantity : null);
      setMakeup(item?.makeup);
      setBuyPrice(item?.buyPrice);
    }, [item]);

    const renderSubChargeLabel = React.useCallback(
      (surchargeId) => {
        const surcharge = arrSurcharge?.find((item) => item?.surchargeId === surchargeId);
        return surcharge?.surchargeName;
      },
      [item?.surchargeId],
    );

    return (
      <Container>
        <Box sx={{ width: '500px', marginRight: '10px' }}>
          <StyledTextSubCharge
            label="Tên dịch vụ"
            variant="outlined"
            type="text"
            size="small"
            multiline
            value={renderSubChargeLabel(item?.surchargeId)}
          />
        </Box>
        <StyledTextFieldPrice
          label="Giá"
          variant="outlined"
          type="text"
          size="small"
          InputProps={{
            inputComponent: NumberFormatCustom,
            disabled: true,
          }}
          value={item?.price}
        />
        {showQuantity && (
          <StyledTextFieldPrice
            label="Số lượng"
            variant="outlined"
            type="text"
            size="small"
            InputProps={{
              inputComponent: NumberFormatCustom,
              disabled: true,
            }}
            value={quantity}
          />
        )}
        <TextFieldMakeUp
          label="%Make Up"
          variant="outlined"
          type="text"
          size="small"
          InputProps={{
            inputComponent: NumberFormatCustom,
            disabled: true,
          }}
          value={makeup}
        />
        <TextFieldBuyPrice
          label="Giá bán"
          variant="outlined"
          type="text"
          size="small"
          InputProps={{
            inputComponent: NumberFormatCustom,
            disabled: true,
          }}
          value={buyPrice}
        />
        <StyledTextField
          label="Đơn vị"
          variant="outlined"
          type="text"
          size="small"
          value={item?.unitCode}
        />
      </Container>
    );
  },
);
export default InputItem;

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '10px',
}));
const StyledTextSubCharge = styled(TextField)(({ theme }) => ({
  marginRight: '10px',
  width: '500px',
}));
const StyledTextFieldPrice = styled(TextField)(({ theme }) => ({
  width: '150px',
  marginRight: '10px',
}));
const TextFieldMakeUp = styled(TextField)(({ theme }) => ({
  width: '120px',
  backgroundColor: '#FCF2EF',
  marginRight: '10px',
}));
const TextFieldBuyPrice = styled(TextField)(({ theme }) => ({
  width: '150px',
  backgroundColor: 'rgba(6, 95, 212, 0.1)',
  marginRight: '10px',
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '150px',
  marginRight: '10px',
}));
