import React, { useState, useEffect, useMemo } from 'react';
import { QUOTE_STATUS } from 'commons/constants';
import { LoadingInContainer } from 'components';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { QuoteTruckingPriceForm } from '../../forms-management/quote-trucking-price-form';
import { QuoteTruckingReturnContForm } from '../../forms-management/quote-trucking-return-cont-form';
import { QuoteTruckingSubChargeForm } from '../../forms-management/quote-trucking-sub-charge-form';
import ApiServices from 'services';

import { HeaderFreight } from './header';
import { ContainerPrice } from './styles';

const TruckingControlQuoteForm = ({ dataTrucking }) => {
  const [groupFeeGroup, setGroupFeeGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cargoTypeList, setCargoTypeList] = useState([]);
  const [locationVTList, setLocationVTList] = useState([]);

  const isDisabledForm = useMemo(() => {
    if (dataTrucking?.isNew) return false;
    if (dataTrucking?.quotesStatus === QUOTE_STATUS.ORIGIN_QUOTE) return false;
    return true;
  }, [dataTrucking]);

  const getCargoType = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getCargoTypes();
      if (data) {
        setCargoTypeList(data || []);
      }
    } catch (err) {
      console.warn('getCargoType', err);
    } finally {
      setLoading(false);
    }
  };
  const getLocationVT = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getLocationVTs();
      if (data) {
        setLocationVTList(data || []);
      }
    } catch (err) {
      console.warn('getLocationVT', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCargoType();
    getLocationVT();
  }, []);

  const getSurchargeByFeeGroupSS = async (id) => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getSurchargeByFeeGroupSS(id, true);
      if (data) {
        const dataMapFeeGroup = data.map((item) => {
          const ind = dataTrucking?.arrSurcharge?.findIndex(
            (i) => i.feeGroupID === item.feeGroupID,
          );
          if (ind !== -1) {
            item.surcharge = dataTrucking?.arrSurcharge[ind]?.surcharge || [];
            item.checked = true;
          } else {
            item.checked = false;
          }

          return item;
        });
        setGroupFeeGroup(dataMapFeeGroup || []);
      }
    } catch (error) {
      console.warn('getSurchargeByFeeGroupSS:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (dataTrucking?.subServiceId) {
      getSurchargeByFeeGroupSS(dataTrucking?.subServiceId);
    }
  }, []);

  const renderSubChargeTrucking = (item, index) => (
    <FormGroup key={item.feeGroupID + index}>
      <FormControlLabel
        sx={{ width: '30%' }}
        control={<Checkbox checked={item.checked} />}
        label={item.feeGroupName}
      />
      {item.checked && (
        <QuoteTruckingSubChargeForm
          arrSurcharge={item.surchargeListFree}
          name={item.feeGroupID}
          value={item?.surcharge}
          disabled={isDisabledForm}
        />
      )}
    </FormGroup>
  );

  if (loading) return <LoadingInContainer />;
  return (
    <>
      <HeaderFreight dataTrucking={dataTrucking} />
      <b>Giá cước</b>

      <ContainerPrice>
        <QuoteTruckingPriceForm
          arrContType={dataTrucking?.arrTypeCargoCode}
          cargoTypeList={cargoTypeList}
          name="arrContTractor"
          requiredTitle="Vui lòng nhập đầy đủ thông tin"
          value={dataTrucking?.arrContTractor}
          disabled={isDisabledForm}
        />
        <FormGroup>
          <FormControlLabel
            sx={{ width: '50%' }}
            control={
              <Checkbox checked={dataTrucking?.subChargeNewPort?.length > 0 ? true : false} />
            }
            label="Lấy hoặc trả cont ở cảng khác"
          />
        </FormGroup>
        {dataTrucking?.subChargeNewPort?.length > 0 && (
          <QuoteTruckingReturnContForm
            locationVTList={locationVTList}
            name="subChargeNewPort"
            requiredTitle="Vui lòng nhập đầy đủ thông tin"
            value={dataTrucking.subChargeNewPort}
            disabled={isDisabledForm}
          />
        )}
        {groupFeeGroup?.map(renderSubChargeTrucking)}
      </ContainerPrice>
    </>
  );
};

export { TruckingControlQuoteForm };
