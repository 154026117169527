export const FORMAT_DATE = {
  DATE_FORMAT: 'DD-MM-YYYY',
  DATE_TIME_FORMAT: 'DD-MM-YYYY HH:mm',
};

export const RESPONSE_STATUSES = {
  OK: 200,
};

export const STORAGE = {
  USER_DATA: 'user_data',
  IS_DARK_MODEL: 'dark_model',
  IS_COLLAPSED: 'is_collapsed',
  ACTIVE_MENU_ITEM: 'active_menu_item',
  USER_DATA_DECODE_TOKEN: 'user_data_decode_token',
  TOKEN_FIREBASE: 'token_firebase',
};
export const GENDER_OPTIONS = {
  MALE: 'Nam',
  FEMALE: 'Nữ',
};
export const INPUT_TYPES = {
  TEXT_INPUT: 'text-input',
  ARRAY_TEXT_INPUT: 'array-text-input',
  SELECTION: 'selection',
  DATE_PICKER: 'date-picker',
  FILE_UPLOAD: 'file-upload',
  HTML_EDITOR: 'hmtl-editor',
  SELECTION_LOCATION: 'selection-location',
};

export const CS_LEVEL_DEFINE = {
  1: 'Công Ty',
  2: 'Khối',
  3: 'Phòng',
  4: 'Bộ Phận',
  5: 'Nhóm',
};

export const REPORT_COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export const PHONE_REG_EXP =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www)|)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
export const EMOJI_MAP = {
  ':)': '😊',
  ':(': '🙁',
  ':D': '😁',
  ';(': '😥',
  ':O': '😮',
  ';)': '😉',
  '8)': '😎',
  '>:@': '😡',
  '<3': '❤️',
  ':*': '😘',
  ':b': '😋',
  ':s': '😏',
  ':p': '😛',
  ':P': '😛',
  ':|': '😐',
  ':@': '😠',
  ':$': '😳',
  ':^)': '😜',
  ':^(': '😞',
  ':^D': '😜',
  ':^O': '😜',
  ':v': '😛',
};

export const VIETNAM_COUNTRY_ID = '62fdbdbfaa2884bf13692bbf';

export const CARRIER_TYPE = {
  0: 'Hãng tàu',
  1: 'Hàng không',
};

export const PORT_TYPE = {
  0: 'Cảng hãng tàu',
  1: 'Cảng hàng không',
};

export const CLIENT_STATUS_ID = {
  LEAD_HOLDING: '621d8c4e2e0312fe91cf1459', // Lead tạm ngưng
  DEAL_WAIT_CARE_AGAIN: '6191d7c6817705b4794e6d86', // Deal chờ chăm sóc lại
  DEAL_SUCCESS: '6191d79d817705b4794e6d85', // Deal thành công
  DEAL: '6191d69e817705b4794e6d84', // Deal
  LEAD_WAIT_CARE_AGAIN: '6191d68a817705b4794e6d83', // Lead chờ chăm sóc lại
  LEAD_TAKING_CARE: '6191d662817705b4794e6d82', // Lead đang chăm sóc
  LEAD_NEW: '6191d64a817705b4794e6d81', // Lead mới
};
export const SERVICES_ID = {
  FREIGHT: '61e7d72c122ba3fabc69860f', // Cước quốc tế
  TRUCKING: '6195b08586034396181cda2b', // Vận tải
  CUSTOMS: '61920f092814bfccf5a5a79e', // Thủ tục hải quan
  WAREHOUSE: '6191d626817705b4794e6d80', // Kho
};
export const SERVICES_OPTION = [
  {
    value: '61e7d72c122ba3fabc69860f',
    label: 'Cước quốc tế',
  },
  {
    value: '6195b08586034396181cda2b',
    label: 'Vận tải',
  },
  {
    value: '61920f092814bfccf5a5a79e',
    label: 'Thủ tục hải quan',
  },
  {
    value: '6191d626817705b4794e6d80',
    label: 'Kho',
  },
];
export const SUB_SERVICES_ID = {
  BONDED_WAREHOUSE: '632d6bf2d72e056b1308df24', // Kho ngoại quan
  TRACTOR: '632d6bd7d72e056b1308df23', // Đầu kéo
  TRUCK: '632d6ba1d72e056b1308df22', // Xe tải
  CUSTOMS_FCL: '63045378cb4c3951f87e03f3', // Full Container Load
  CUSTOMS_LCL: '6304536fcb4c3951f87e03f2', // Less than Container Load
  CUSTOMS_AIR: '63045364cb4c3951f87e03f1', // Thủ tục hải quan hàng không
  SEA_FCL: '63045357cb4c3951f87e03f0', // Đường biển FCL
  SEA_LCL: '62fdde283d42e9c50d8d00df', // Đường biển LCL
  AIR: '62fdde1c3d42e9c50d8d00de', // Đường hàng không
};

export const FEE_GROUP_ID = {
  HANDLING_CHARGE: '633bfcc619478daf24bb43c9', // Handling charges
  CUSTOM_CLEARANCE_CHARGE_INBOUND: '633bfcd719478daf24bb43ca', // Customs clearance service charge-inbound
  CUSTOM_CLEARANCE_CHARGE_OUTBOUND: '633bfcd719478daf24bb43ca', // Customs clearance service charge-outbound
  TRANSPORT_SERVICE_CHARGE: '', // Transport service charge
  OTHER_SERVICES: '633bfd9519478daf24bb43cc', // Other services
};

export const USAGE_STATUS = {
  0: {
    color: 'success',
    label: 'Còn sử dụng',
  },
  1: {
    color: 'warning',
    label: 'Không sử dụng',
  },
};
export const PRICE_BOARD_STATUS = {
  WAIT_FOR_APPROVE: 0, // Chờ xác nhận
  APPROVED: 1, // Xác nhận
  REJECT: 9, // Từ chối
};
export const PRICE_BOARD_STATUS_OPTIONS = [
  {
    value: 0,
    label: 'Chờ xác nhận',
  },
  {
    value: 1,
    label: 'Xác nhận',
  },
  {
    value: 9,
    label: 'Từ chối',
  },
];
export const ARISING_TYPE = {
  0: 'Không chi phí',
  1: 'Có chi phí',
};

export const SEX = {
  0: 'Nữ',
  1: 'Nam',
};

export const LEVEL_DASHBOARD = {
  0: '0 - Cấp độ cha',
  1: '1 - Cấp độ con',
};

export const CHECK_PRICE_STATUS = {
  0: {
    label: 'Khởi tạo',
  },
  1: {
    color: 'warning',
    label: 'Chờ phản hồi',
  },
  2: {
    color: 'primary',
    label: 'Đang xử lý',
  },
  3: {
    color: 'success',
    label: 'Đã phản hồi',
  },
  9: {
    color: 'error',
    label: 'Từ chối',
  },
};
export const CHECK_STATUS = {
  0: 'Chưa chuyển báo giá',
  1: 'Đã chuyển báo giá',
};
export const REQ_CHECK_PRICE_STATUS = {
  CREATE_CHECK_PRICE: 0, // Khởi tạo
  WAIT_RES_CHECK_PRICE: 1, // Chờ phản hồi
  RES_CHECK_PRICE: 3, // Đã phản hồi
  REJECT_CHECK_PRICE: 9, // Từ chối
};
export const QUOTE_STATUS = {
  ORIGIN_QUOTE: 0, // Khởi tạo
  REQUEST_APPROVE: 1, // Trình duyệt
  APPROVED: 2, // Duyệt
  REJECT_APPROVE: 9, // Không duyệt
  SENT_QUOTE: 10, // Đã gửi báo giá
  ACCEPTED_QUOTE: 11, // Đã chấp nhận báo giá
  REJECT_QUOTE: 19, // Từ chối báo giá
};
export const QUOTE_STATUS_OPTIONS = {
  0: {
    label: 'Khởi tạo',
  },
  1: {
    color: 'warning',
    label: 'Trình duyệt',
  },
  2: {
    color: 'success',
    label: 'Đã duyệt',
  },
  9: {
    color: 'primary',
    label: 'Không duyệt',
  },
  10: {
    color: 'success',
    label: 'Đã gửi',
  },
  11: {
    color: 'success',
    label: 'Chấp nhập',
  },
  19: {
    color: 'error',
    label: 'Từ chối',
  },
};
export const QUOTE_STATE = {
  0: 'Khởi tạo',
  1: 'Thương lượng',
  2: 'Đã chuyển hợp đồng',
};
export const CONTRACT_STATUS_OPTIONS = {
  0: {
    label: 'Khởi tạo',
  },
  1: {
    color: 'warning',
    label: 'Trình ký',
  },
  2: {
    color: 'success',
    label: 'Xác nhận',
  },
  3: {
    color: 'primary',
    label: 'Hết hạn',
  },
  10: {
    color: 'error',
    label: 'Hủy',
  },
};
export const CONTRACT_STATUS = {
  ORIGIN: 0, // Khởi tạo
  REQUEST: 1, // Trình ký
  APPROVED: 2, // Xác nhận
  EXPIRED: 3, // Hết hạn
  CANCEL: 10, // Hủy
};
