import React from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput, ArrayInput, Selection } from 'components';
import { Button } from '@mui/material';
import { Formik } from 'formik';
import ApiServices from 'services';
import Storage from 'storage';
import { PHONE_REG_EXP } from 'commons/constants';
import { ContainerActions, ContainerForm } from './styles';
import Utils from 'commons/utils';
const FORM_WIDTH = '600px';

export const FormDetail = ({
  selected,
  setSelected,
  reloadData,
  onAccept,
  onReject,
  isAccept,
  handleCloseForm,
  ...props
}) => {
  const handleClose = () => {
    handleCloseForm && handleCloseForm();
  };

  const createFn = async (values, { setSubmitting }) => {
    values.companyId = Storage?.user?.CompanyId;
    if (!values.foundingDate) {
      delete values.foundingDate;
    }

    if (!values.clientSourceId) {
      delete values.clientSourceId;
    }

    setSubmitting(true);
    try {
      const { status } = await ApiServices.createClientTrash(values);
      if (status === 200) {
        window.UIMessage?.success('Thêm mới thành công');
        setSelected(null);
        reloadData();
      } else {
        window.UIMessage?.error('Thêm mới thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const updateFn = async (values, { setSubmitting, accept = false }) => {
    let dataSend = {
      id: values.id,
      code: values.code,
      name: values.name,
      tax_Code: values.tax_Code,
      arrAddress: values.arrAddress,
      arrNumberPhone: values.arrNumberPhone,
      arrFax: values.arrFax,
      email: values.email,
      note: values.note,
      clientSourceId: values.clientSourceId,
      foundingDate: values.foundingDate,
      companyType: values.companyType,
    };

    if (!dataSend.foundingDate) {
      delete dataSend.foundingDate;
    }

    if (!dataSend.clientSourceId) {
      delete dataSend.clientSourceId;
    }
    setSubmitting(true);
    try {
      const res = await ApiServices.updateClientTrash(dataSend.id, dataSend);
      if (res?.data) {
        handleClose();
        if (accept) {
          window.UIMessage?.success('Cập nhật thành công', 'Chọn dịch vụ để xác nhận');
          reloadData();
          onAccept(res?.data);
        } else {
          window.UIMessage?.success('Cập nhật thành công');
          reloadData();
        }
      } else {
        window.UIMessage?.error('Cập nhật thất bại !!!');
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };
  const disabledAcceptButton = !selected?.arrNumberPhone?.length && !selected?.email;

  return (
    <>
      <HandleFormDetail
        {...{ selected, width: FORM_WIDTH }}
        showTitleTab={selected?.isNew ? 'Tạo mới' : `Mã: ${selected?.code}`}
        onClose={handleClose}
      >
        <Formik
          initialValues={{
            code: '',
            name: '',
            tax_Code: '',
            arrAddress: [],
            arrNumberPhone: [],
            arrFax: [],
            email: '',
            foundingDate: '',
            note: '',
            clientSourceId: '',
            companyType: '',
            ...selected,
          }}
          validationSchema={Yup.object().shape(
            {
              code: Yup.string()
                .max(15, 'Mã chỉ được nhập tối đa 15 ký tự')
                .required('Vui lòng nhập mã nhóm!!!'),
              name: Yup.string().max(255).required('Vui lòng nhập tên công ty!!!'),
              tax_Code: Yup.string().max(255).required('Vui lòng nhập mã số thuế'),
              ...(isAccept && {
                email: Yup.string()
                  .email('Email không hợp lệ')
                  .when('arrNumberPhone', {
                    is: (val) => (val?.length ? false : true),
                    then: (schema) => schema.required('Vui lòng nhập email'),
                  }),
                arrNumberPhone: Yup.array()
                  .when('email', {
                    is: (val) => (val?.length ? false : true),
                    then: (schema) =>
                      schema
                        .required('Vui lòng nhập số điện thoại')
                        .min(1, 'Vui lòng nhập ít nhất 1 số điện thoại'),
                  })
                  .of(
                    Yup.string()

                      .matches(
                        PHONE_REG_EXP,
                        ({ value }) => 'Số điện thoại "' + value + '" không hợp lệ',
                      )
                      .min(1, 'Số điện thoại không hợp lệ')
                      .max(14, 'Số điện thoại có 14 ký tự'),
                  ),
              }),
            },
            ['email', 'arrNumberPhone'],
          )}
          onSubmit={(values, { setSubmitting }) => {
            let phoneError = [];
            if (values?.arrNumberPhone?.length) {
              values?.arrNumberPhone?.forEach((phone) => {
                const checkIsNumberPhone = Utils.isNumber(phone);
                if (!checkIsNumberPhone) {
                  phoneError.push(phone);
                  return;
                }
                const checkIsLength = phone?.length <= 14 && phone?.length > 1;
                if (!checkIsLength) {
                  phoneError.push(phone);
                  return;
                }
              });
            }
            if (phoneError?.length > 0) {
              window.UIMessage?.error(
                'Số điện thoại không hợp lệ',
                `Các số điện thoại không hợp lệ: ${phoneError.join(', ')}`,
              );
              setSubmitting(false);
              return;
            }
            if (values?.email) {
              const checkEmail = Utils.isEmail(values?.email);
              if (!checkEmail) {
                window.UIMessage?.error('Email không hợp lệ');
                setSubmitting(false);
                return;
              }
            }
            // if (selected?.isNew) {
            //   return createFn(values, { setSubmitting });
            // }
            // if (isAccept) {
            //   return updateFn(values, { setSubmitting, accept: true });
            // }
            // return updateFn(values, { setSubmitting });
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <ContainerForm>
                <TextInput
                  required
                  error={Boolean(touched.code && errors.code)}
                  helperText={touched.code && errors.code}
                  label="Mã"
                  name="code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.code}
                />
                <TextInput
                  required
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  label="Tên công ty"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                />
                <TextInput
                  required
                  error={Boolean(touched.tax_Code && errors.tax_Code)}
                  helperText={touched.tax_Code && errors.tax_Code}
                  label="Mã số thuế"
                  name="tax_Code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.tax_Code}
                />
                <ArrayInput
                  value={values.arrAddress}
                  fullWidth
                  onChange={handleChange}
                  label="Địa chỉ"
                  name="arrAddress"
                />
                <ArrayInput
                  value={values.arrNumberPhone}
                  helperText={touched.arrNumberPhone && errors.arrNumberPhone}
                  error={Boolean(touched.arrNumberPhone && errors.arrNumberPhone)}
                  fullWidth
                  onChange={handleChange}
                  label="SĐT"
                  name="arrNumberPhone"
                />
                <ArrayInput
                  value={values.arrFax}
                  fullWidth
                  onChange={handleChange}
                  label="Fax"
                  name="arrFax"
                />
                <TextInput
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  label="Email"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                />
                <TextInput
                  error={Boolean(touched.foundingDate && errors.foundingDate)}
                  helperText={touched.foundingDate && errors.foundingDate}
                  label="Thành Lập"
                  name="foundingDate"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.foundingDate}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextInput
                  error={Boolean(touched.companyType && errors.companyType)}
                  helperText={touched.companyType && errors.companyType}
                  label="Loại công ty"
                  name="companyType"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.companyType}
                />
                <Selection
                  error={Boolean(touched.clientSourceId && errors.clientSourceId)}
                  helperText={touched.clientSourceId && errors.clientSourceId}
                  label="Nguồn khách hàng"
                  name="clientSourceId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.clientSourceId}
                  syncData={ApiServices.getClientSources}
                />
                <TextInput
                  error={Boolean(touched.note && errors.note)}
                  helperText={touched.note && errors.note}
                  label="Ghi chú"
                  name="note"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.note}
                />
              </ContainerForm>

              <ContainerActions>
                {!selected?.isNew && !isAccept && (
                  <Button
                    color="error"
                    disabled={isSubmitting}
                    size="small"
                    variant="contained"
                    sx={{
                      mr: 1,
                      mt: 2,
                      // ml: 'auto',
                    }}
                    onClick={() => onReject(selected) && setSelected(null)}
                  >
                    Từ chối
                  </Button>
                )}
                {/* {!selected?.isNew && !isAccept && (
                  <Button
                    color="success"
                    size="small"
                    variant="contained"
                    sx={{
                      mr: 1,
                      mt: 2,
                    }}
                    // onClick={() => handleTestAccept(values)}
                    onClick={() => onAccept(selected) && setSelected(null)}
                    disabled={isSubmitting || Boolean(disabledAcceptButton)}
                  >
                    Chấp nhận
                  </Button>
                )} */}
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  size="small"
                  type="submit"
                  variant="contained"
                  sx={{
                    ml: 1,
                    mt: 2,
                  }}
                >
                  {selected?.isNew ? 'Thêm mới' : isAccept ? 'Cập nhật và Xác nhận' : 'Cập nhật'}
                </Button>
              </ContainerActions>
            </form>
          )}
        </Formik>
      </HandleFormDetail>
    </>
  );
};
