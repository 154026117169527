import * as React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  CardContent,
  Card,
  TextField,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import ApiServices from 'services';
import Storage from 'storage';

const ChangePassword = () => {
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const submitChangePassword = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const dataSend = { ...values };
    delete dataSend.confirmPassword;

    try {
      const { data } = await ApiServices.changePassword(dataSend);
      if (data) {
        window.UIMessage?.success(data);
        values.oldPassword = '';
        values.password = '';
        values.confirmPassword = '';
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid
        item
        xs={6}
        sx={{
          backgroundColor: 'background.default',
          borderTopRightRadius: '10px',
          borderBottomRightRadius: '10px',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'background.default',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                code: Storage?.user?.Code,
                oldPassword: '',
                password: '',
                confirmPassword: '',
              }}
              validationSchema={Yup.object().shape({
                code: Yup.string().max(255).required('Vui lòng nhập tài khoản'),
                oldPassword: Yup.string().max(255).required('Vui lòng nhập mật khẩu cũ'),
                password: Yup.string().max(255).required('Vui lòng nhập mật khẩu mới'),
                confirmPassword: Yup.string()
                  .when('password', {
                    is: (val) => (val?.length > 0 ? true : false),
                    then: Yup.string().oneOf([Yup.ref('password')], 'Mật khẩu không khớp'),
                  })
                  .required('Vui lòng nhập lại mật khẩu mới'),
              })}
              onSubmit={(values, { setSubmitting }) => {
                submitChangePassword(values, { setSubmitting });
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box sx={{ mb: 1 }}>
                    <Typography
                      color="textPrimary"
                      variant="h4"
                      sx={{ fontWeight: 'h3', textAlign: 'center' }}
                    >
                      Đổi mật khẩu
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.code && errors.code)}
                    fullWidth
                    helperText={touched.code && errors.code}
                    label="Tài khoản"
                    margin="normal"
                    name="code"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.code}
                    variant="outlined"
                    disabled
                  />
                  <TextField
                    error={Boolean(touched.oldPassword && errors.oldPassword)}
                    fullWidth
                    helperText={touched.oldPassword && errors.oldPassword}
                    label="Mật khẩu cũ"
                    margin="normal"
                    name="oldPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={showOldPassword ? 'text' : 'password'}
                    value={values.oldPassword}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowOldPassword(!showOldPassword)}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showOldPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Mật khẩu mới"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {values.password && (
                    <TextField
                      // error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                      fullWidth
                      // helperText={touched.confirmPassword && errors.confirmPassword}
                      label="Nhập lại mật khẩu mới"
                      margin="normal"
                      name="confirmPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type={showConfirmPassword ? 'text' : 'password'}
                      value={values.confirmPassword}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  <div className="error" style={{ color: 'red' }}>
                    {errors.confirmPassword}
                  </div>
                  <Box sx={{ py: 2 }}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {isSubmitting ? 'Đang tải...' : 'XÁC NHẬN'}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </Box>
      </Grid>
    </Box>
  );
};

export { ChangePassword };
