import React, { useState, useEffect, useMemo } from 'react';
import { SUB_SERVICES_ID, QUOTE_STATUS, FEE_GROUP_ID } from 'commons/constants';
import { LoadingInContainer } from 'components';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { QuoteWhLeasingPriceForm } from '../../forms-management/quote-wh-leasing-price-form';
import { QuoteWhHandlingChargeForm } from '../../forms-management/quote-wh-handling-charge-form';
import { QuoteWhInboundOutboundForm } from '../../forms-management/quote-wh-inbound-outbound-form';
import { QuoteWhTransportChargeForm } from '../../forms-management/quote-wh-transport-charge-form';
import { QuoteWhOtherServicesForm } from '../../forms-management/quote-wh-other-services-form';
import ApiServices from 'services';
import { ContainerPrice, ContainerItemService } from './styles';

const WarehouseControlQuoteForm = ({ dataWarehouse }) => {
  const [groupFeeGroup, setGroupFeeGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leaseSpaceList, setLeaseSpaceList] = useState([]);

  const isDisabledForm = useMemo(() => {
    if (dataWarehouse?.isNew) return false;
    if (dataWarehouse?.quotesStatus === QUOTE_STATUS.ORIGIN_QUOTE) return false;
    return true;
  }, [dataWarehouse]);

  const getLeaseSpace = async () => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getLeaseSpaces();
      if (data) {
        setLeaseSpaceList(data || []);
      }
    } catch (err) {
      console.warn('getLeaseSpace', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLeaseSpace();
  }, []);

  const getSurchargeByFeeGroupSS = async (id) => {
    setLoading(true);
    try {
      const { data } = await ApiServices.getSurchargeByFeeGroupSSAndFeeId(
        FEE_GROUP_ID.OTHER_SERVICES,
        SUB_SERVICES_ID.BONDED_WAREHOUSE,
      );
      if (data) {
        const dataMapFeeGroup = data.map((item) => {
          const ind = dataWarehouse?.arrSurcharge?.findIndex(
            (i) => i.feeGroupID === item.feeGroupID,
          );
          if (ind !== -1) {
            item.surcharge = dataWarehouse?.arrSurcharge[ind]?.surcharge || [];
            item.checked = true;
          } else {
            item.checked = false;
          }

          return item;
        });
        setGroupFeeGroup(dataMapFeeGroup || []);
      }
    } catch (error) {
      console.warn('getSurchargeByFeeGroupSS:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (dataWarehouse?.subServiceId) {
      getSurchargeByFeeGroupSS(dataWarehouse?.subServiceId);
    }
  }, []);

  const renderSubChargeWarehouse = (item, index) => (
    <FormGroup key={item.feeGroupID + index}>
      <FormControlLabel
        sx={{ width: 'auto' }}
        control={<Checkbox checked={item.checked} />}
        label={item.feeGroupName}
      />
      {item.checked && (
        <QuoteWhOtherServicesForm
          arrSurcharge={item?.surchargeListFree}
          name={item.feeGroupID}
          onChange={(event) => console.log(event)}
          value={item?.surcharge}
        />
      )}
    </FormGroup>
  );

  const renderLabelSubPrice = (label = '', checked = false) => (
    <FormGroup>
      <FormControlLabel
        sx={{ width: 'auto' }}
        control={<Checkbox checked={checked} />}
        label={label}
      />
    </FormGroup>
  );

  if (loading) return <LoadingInContainer />;
  return (
    <>
      <ContainerItemService>
        <i>
          {dataWarehouse?.serviceName} - {dataWarehouse?.subServiceName}
        </i>
      </ContainerItemService>
      <ContainerPrice>
        <b>Warehouse leasing price</b>
        {leaseSpaceList?.length > 0 && (
          <QuoteWhLeasingPriceForm
            name="warehouseLeasingPrice"
            requiredTitle="Vui lòng nhập đầy đủ thông tin"
            value={dataWarehouse?.warehouseLeasingPrice}
            leaseSpaceList={leaseSpaceList}
            disabled={isDisabledForm}
          />
        )}

        {renderLabelSubPrice('Handling charges', dataWarehouse?.listHandlingCharges?.length > 0)}
        {dataWarehouse?.listHandlingCharges?.length > 0 && (
          <QuoteWhHandlingChargeForm
            name="listHandlingCharges"
            value={dataWarehouse?.listHandlingCharges}
          />
        )}

        {renderLabelSubPrice(
          'Customs clearance service charges Inbound',
          dataWarehouse?.listCCSChargeInbound?.length > 0,
        )}
        {dataWarehouse?.listCCSChargeInbound?.length > 0 && (
          <QuoteWhInboundOutboundForm
            name="listCCSChargeOutBound"
            value={dataWarehouse?.listCCSChargeInbound}
          />
        )}

        {renderLabelSubPrice(
          'Customs clearance service charges Outbound',
          dataWarehouse?.listCCSChargeOutBound?.length > 0,
        )}
        {dataWarehouse?.listCCSChargeOutBound?.length > 0 && (
          <QuoteWhInboundOutboundForm
            name="listCCSChargeOutBound"
            value={dataWarehouse?.listCCSChargeOutBound}
            isOutbound={true}
          />
        )}

        {renderLabelSubPrice(
          'Transport service charge',
          dataWarehouse?.transportServiceCharge?.length > 0,
        )}
        {dataWarehouse?.transportServiceCharge?.length > 0 && (
          <QuoteWhTransportChargeForm
            name="transportServiceCharge"
            value={dataWarehouse?.transportServiceCharge}
          />
        )}

        {groupFeeGroup?.map(renderSubChargeWarehouse)}
      </ContainerPrice>
    </>
  );
};

export { WarehouseControlQuoteForm };
